"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.function.name");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "ElementBaseInfo",
  props: {
    businessObject: Object,
    model: Object // 流程模型的数据
  },
  data: function data() {
    return {
      elementBaseInfo: {},
      // 流程表单的下拉框的数据
      forms: [],
      // 流程模型的校验
      rules: {
        key: [{
          required: true,
          message: "流程标识不能为空",
          trigger: "blur"
        }],
        name: [{
          required: true,
          message: "流程名称不能为空",
          trigger: "blur"
        }]
      }
    };
  },
  watch: {
    businessObject: {
      immediate: false,
      handler: function handler(val) {
        var _this = this;
        if (val) {
          this.$nextTick(function () {
            return _this.resetBaseInfo();
          });
        }
      }
    }
    // 'model.key': {
    //   immediate: false,
    //   handler: function (val) {
    //     this.handleKeyUpdate(val)
    //   }
    // }
  },
  created: function created() {
    var _this2 = this;
    // 针对上传的 bpmn 流程图时，需要延迟 1 秒的时间，保证 key 和 name 的更新
    setTimeout(function () {
      _this2.handleKeyUpdate(_this2.model.key);
      _this2.handleNameUpdate(_this2.model.name);
    }, 1000);
  },
  methods: {
    resetBaseInfo: function resetBaseInfo() {
      var _window, _window$bpmnInstances;
      this.bpmnElement = (_window = window) === null || _window === void 0 ? void 0 : (_window$bpmnInstances = _window.bpmnInstances) === null || _window$bpmnInstances === void 0 ? void 0 : _window$bpmnInstances.bpmnElement;
      this.elementBaseInfo = JSON.parse(JSON.stringify(this.bpmnElement.businessObject));
    },
    handleKeyUpdate: function handleKeyUpdate(value) {
      // 校验 value 的值，只有 XML NCName 通过的情况下，才进行赋值。否则，会导致流程图报错，无法绘制的问题
      if (!value) {
        return;
      }
      if (!value.match(/[a-zA-Z_][\-_.0-9a-zA-Z$]*/)) {
        console.log("key 不满足 XML NCName 规则，所以不进行赋值");
        return;
      }
      console.log("key 满足 XML NCName 规则，所以进行赋值");

      // 在 BPMN 的 XML 中，流程标识 key，其实对应的是 id 节点
      this.elementBaseInfo["id"] = value;
      this.updateBaseInfo("id");
    },
    handleNameUpdate: function handleNameUpdate(value) {
      if (!value) {
        return;
      }
      this.elementBaseInfo["name"] = value;
      this.updateBaseInfo("name");
    },
    handleDescriptionUpdate: function handleDescriptionUpdate(value) {
      // TODO 芋艿：documentation 暂时无法修改，后续在看看
      // this.elementBaseInfo['documentation'] = value;
      // this.updateBaseInfo('documentation');
    },
    updateBaseInfo: function updateBaseInfo(key) {
      // 触发 elementBaseInfo 对应的字段
      var attrObj = Object.create(null);
      attrObj[key] = this.elementBaseInfo[key];
      if (key === "id") {
        window.bpmnInstances.modeling.updateProperties(this.bpmnElement, {
          id: this.elementBaseInfo[key],
          di: {
            id: "".concat(this.elementBaseInfo[key], "_di")
          }
        });
      } else {
        window.bpmnInstances.modeling.updateProperties(this.bpmnElement, attrObj);
      }
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.bpmnElement = null;
  }
};
exports.default = _default;