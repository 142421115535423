var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu fcenter" },
        [
          _c(
            "div",
            {
              staticClass: "fcenter my_approve",
              on: { click: _vm.toMyApprove }
            },
            [
              _c("div", { staticClass: "el-icon-document" }),
              _vm._v(" "),
              _c("div", { staticClass: "user_name" }, [_vm._v("我的审批")])
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" }
            },
            [
              _c("div", { staticClass: "avatar-wrapper flex1" }, [
                _c("div", { staticClass: "el-icon-arrow-down el-icon--right" })
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", [_vm._v("我的消息")]),
                  _vm._v(" "),
                  _c("el-dropdown-item", [_vm._v("待我审批")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" }
            },
            [
              _c(
                "div",
                { staticClass: "avatar-wrapper flex1" },
                [
                  _c("div", { staticClass: "user_name" }, [
                    _vm._v(_vm._s(_vm.nickName))
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "el-icon-arrow-down el-icon--right"
                  }),
                  _vm._v(" "),
                  _vm.user.avatarURL
                    ? _c("el-avatar", {
                        staticClass: "user-avatar",
                        attrs: { src: _vm.user.avatarURL }
                      })
                    : _c("el-avatar", { staticClass: "user-avatar ccenter" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.nickName.slice(-2)) +
                            "\n        "
                        )
                      ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "span",
                    { on: { click: _vm.userchange } },
                    [_c("el-dropdown-item", [_vm._v(" 角色切换 ")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    { attrs: { to: "/user/center" } },
                    [_c("el-dropdown-item", [_vm._v(" 个人中心 ")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticStyle: { display: "block" },
                      on: { click: _vm.open }
                    },
                    [
                      _c("el-dropdown-item", { attrs: { divided: "" } }, [
                        _vm._v(" 退出登录 ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.userchangeVisible,
            width: "35%",
            "before-close": _vm.handleClose,
            "modal-append-to-body": false,
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.userchangeVisible = $event
            }
          }
        },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.value,
                callback: function($$v) {
                  _vm.value = $$v
                },
                expression: "value"
              }
            },
            _vm._l(_vm.rolesArray, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: {
                  label: item.dataScope + "/" + item.name,
                  value: item.id
                }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.rolesubmit } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }