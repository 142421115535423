"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CustomViewer = CustomViewer;
var _inherits = _interopRequireDefault(require("inherits"));
var _Viewer = _interopRequireDefault(require("bpmn-js/lib/Viewer"));
var _zoomscroll = _interopRequireDefault(require("diagram-js/lib/navigation/zoomscroll"));
var _movecanvas = _interopRequireDefault(require("diagram-js/lib/navigation/movecanvas"));
function CustomViewer(options) {
  _Viewer.default.call(this, options);
}
(0, _inherits.default)(CustomViewer, _Viewer.default);
CustomViewer.prototype._modules = [].concat(_Viewer.default.prototype._modules, [_zoomscroll.default, _movecanvas.default]);