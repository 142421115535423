"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.download = download;
exports.initData = initData;
var _slicedToArray2 = _interopRequireDefault(require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime-corejs2/helpers/slicedToArray"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es7.object.entries");
var _request = _interopRequireDefault(require("@/utils/request"));
var _qs = _interopRequireDefault(require("qs"));
function filterNonNull(obj) {
  return Object.fromEntries(Object.entries(obj).filter(function (_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      k = _ref2[0],
      v = _ref2[1];
    return v;
  }));
}
function initData(url, params) {
  return (0, _request.default)({
    url: url + '?' + _qs.default.stringify(filterNonNull(params), {
      indices: false
    }),
    method: 'get'
  });
}
function download(url, params) {
  return (0, _request.default)({
    url: url + (JSON.stringify(params) === '{}' ? '' : '?') + _qs.default.stringify(params, {
      indices: false
    }),
    method: 'get',
    responseType: 'blob'
  });
}