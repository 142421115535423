"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var useDictStore = {
  state: {
    // dicts: new Array(),
    dicts: {
      dict: {},
      label: {}
    },
    /* dictNames[i].status: 0-对应字典未赋值,1-对应字典已赋值 */
    dictNames: []
  },
  mutations: {
    // 插入字典名字
    pushDictName: function pushDictName(state, name) {
      if (!state.dictNames.includes(name)) {
        state.dictNames.push(name);
      }
    },
    // 获取字典
    getStoreDict: function getStoreDict(state, dictName) {
      if (dictName == null && dictName == "") {
        return null;
      }
      try {
        for (var i = 0; i < state.dicts.length; i++) {
          if (state.dicts[i].dictName == dictName) {
            return state.dicts[i];
          }
        }
      } catch (e) {
        return null;
      }
    },
    // 设置字典dict
    setStoreDict: function setStoreDict(state, _ref) {
      var data = Object.assign({}, _ref);
      if (data.key) {
        state.dicts[data.key][data._key] = data._value;
      } else {
        state.dicts[data._key] = data._value;
      }
    },
    // 删除字典
    removeStoreDict: function removeStoreDict(state, dictName) {
      var bln = false;
      for (var i = 0; i < state.dicts.length; i++) {
        if (state.dicts[i].dictName == dictName) {
          state.dicts.splice(i, 1);
          return true;
        }
      }
      return bln;
    },
    // 清空字典
    cleanStoreDict: function cleanStoreDict(state) {
      state.dicts = new Array();
    },
    // 初始字典
    initStoreDict: function initStoreDict() {}
  }
};
var _default = useDictStore;
exports.default = _default;