"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "ElementOtherConfig",
  props: {
    id: String
  },
  data: function data() {
    return {
      documentation: ""
    };
  },
  watch: {
    id: {
      immediate: true,
      handler: function handler(id) {
        var _this = this;
        if (id && id.length) {
          this.$nextTick(function () {
            var _window$bpmnInstances;
            var documentations = (_window$bpmnInstances = window.bpmnInstances.bpmnElement.businessObject) === null || _window$bpmnInstances === void 0 ? void 0 : _window$bpmnInstances.documentation;
            _this.documentation = documentations && documentations.length ? documentations[0].text : "";
          });
        } else {
          this.documentation = "";
        }
      }
    }
  },
  methods: {
    updateDocumentation: function updateDocumentation() {
      this.bpmnElement && this.bpmnElement.id === this.id || (this.bpmnElement = window.bpmnInstances.elementRegistry.get(this.id));
      var documentation = window.bpmnInstances.bpmnFactory.create("bpmn:Documentation", {
        text: this.documentation
      });
      window.bpmnInstances.modeling.updateProperties(this.bpmnElement, {
        documentation: [documentation]
      });
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.bpmnElement = null;
  }
};
exports.default = _default;