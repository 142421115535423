"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = validatePhone;
exports.font3behind2 = font3behind2;
exports.font7behind2 = font7behind2;
exports.font8behind2 = font8behind2;
exports.isArray = isArray;
exports.isExternal = isExternal;
exports.isString = isString;
exports.isWeakPassword = isWeakPassword;
exports.isvalidPhone = isvalidPhone;
exports.noFuNo0 = noFuNo0;
exports.uppercaseLetters = uppercaseLetters;
exports.validAlphabets = validAlphabets;
exports.validBanknum = validBanknum;
exports.validEmail = validEmail;
exports.validEmail1 = validEmail1;
exports.validIdentityCard = validIdentityCard;
exports.validIntNumber = validIntNumber;
exports.validLowerCase = validLowerCase;
exports.validMoney = validMoney;
exports.validMoneyNoZero = validMoneyNoZero;
exports.validMoneyNoZero1 = validMoneyNoZero1;
exports.validName = validName;
exports.validNameChineseOrEnglish = validNameChineseOrEnglish;
exports.validNonnegativeNumber = validNonnegativeNumber;
exports.validNotZhWords = validNotZhWords;
exports.validNumber = validNumber;
exports.validPositiveNumber = validPositiveNumber;
exports.validSocial = validSocial;
exports.validTo1000 = validTo1000;
exports.validURL = validURL;
exports.validUpperCase = validUpperCase;
exports.validUsername = validUsername;
exports.validWater = validWater;
exports.validWord = validWord;
exports.validWordAddress = validWordAddress;
exports.validateEmpty = validateEmpty;
exports.validateIP = validateIP;
exports.validateIdNo = validateIdNo;
exports.validateLetter = validateLetter;
exports.validatePassword = validatePassword;
exports.validatePhoneTwo = validatePhoneTwo;
exports.validateSerialNo = validateSerialNo;
exports.validateTelephone = validateTelephone;
exports.validateWeixin = validateWeixin;
exports.zeroFront8Behind2 = zeroFront8Behind2;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.regexp.to-string");
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validUsername(str) {
  var valid_map = ["superAdm", "editor"];
  return valid_map.indexOf(str.trim()) >= 0;
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
function validURL(url) {
  var reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validLowerCase(str) {
  var reg = /^[a-z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validUpperCase(str) {
  var reg = /^[A-Z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validAlphabets(str) {
  var reg = /^[A-Za-z]+$/;
  return reg.test(str);
}

/**
 * 校验邮箱
 * @param {string} email
 * @returns {Boolean}
 */
function validEmail(email) {
  var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}
function isvalidPhone(phone) {
  var reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
  return reg.test(phone);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function isString(str) {
  if (typeof str === "string" || str instanceof String) {
    return true;
  }
  return false;
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
function isArray(arg) {
  if (typeof Array.isArray === "undefined") {
    return Object.prototype.toString.call(arg) === "[object Array]";
  }
  return Array.isArray(arg);
}

/**
 * 是否合法IP地址
 * @param rule
 * @param value
 * @param callback
 */
function validateIP(rule, value, callback) {
  if (value === "" || value === undefined || value == null) {
    callback();
  } else {
    var reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
    if (value && !reg.test(value)) {
      callback(new Error("请输入正确的IP地址"));
    } else {
      callback();
    }
  }
}

/* 是否手机号码或者固话*/
function validatePhoneTwo(rule, value, callback) {
  var reg = /^((0\d{2,3}-\d{7,8})|(1[3-9]\d{9}))$/;
  if (value && !reg.test(value)) {
    callback(new Error("请输入正确的电话号码或者固话号码"));
  } else {
    callback();
  }
}

/* 是否固话*/
function validateTelephone(rule, value, callback) {
  var reg = /0\d{2}-\d{7,8}/;
  if (value && !reg.test(value)) {
    callback(new Error("请输入正确的固话（格式：区号+号码,如010-1234567）"));
  } else {
    callback();
  }
}

/* 是否手机号码*/
// 这里是默认导出哦！！！！
function validatePhone(rule, value, callback) {
  var reg = /^[1][3-9][0-9]{9}$/;
  if (value === null || value === "") {
    callback();
  } else {
    if (!reg.test(value)) {
      callback(new Error("请输入正确的电话号码"));
    } else {
      callback();
    }
  }
}

/* 是否身份证号码*/
function validateIdNo(rule, value, callback) {
  var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  if (value && !reg.test(value)) {
    callback(new Error("请输入正确的身份证号码"));
  } else {
    callback();
  }
}

// 正整数
function validPositiveNumber(rule, value, callback) {
  var reg = /^[1-9]+[0-9]*$/;
  if (value && !reg.test(value)) {
    callback(new Error("请输入正整数"));
  } else {
    callback();
  }
}
// 校验金额含有0
function validMoney(rule, value, callback) {
  var reg = /(?!^0+\.0{0,2}$)(^\d+$|^\d+\.\d{2}$)/;
  if (value && !reg.test(value)) {
    callback(new Error("请输入正确的金额，保留两位小数"));
  } else {
    callback();
  }
}

// 校验金额不含0
function validMoneyNoZero(rule, value, callback) {
  var reg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/;
  if (value === "0" || value === "0.0" || value === "0.00") {
    callback(new Error("不能为0"));
  } else {
    if (value && !reg.test(value)) {
      callback(new Error("最多两位正小数"));
    } else {
      callback();
    }
  }
}

// 校验含0 保留两位小数
function validMoneyNoZero1(rule, value, callback) {
  var reg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/;
  if (value && !reg.test(value)) {
    callback(new Error("请保留两位小数"));
  } else {
    callback();
  }
}

// 正数
function validNumber(rule, value, callback) {
  var reg = /(^[0-9]+\.\d+$)|(^[0-9]+$)/;
  if (value && !reg.test(value)) {
    callback(new Error("请输入正确格式数字"));
  } else {
    if (value == 0) {
      callback(new Error("不能为0"));
    } else {
      callback();
    }
  }
}

// 非负数
function validNonnegativeNumber(rule, value, callback) {
  var reg = /(^[0-9]+\.\d+$)|(^[0-9]+$)/;
  if (value && !reg.test(value)) {
    callback(new Error("请输入非负数"));
  } else {
    callback();
  }
}
// 小于1000的非负数
function validTo1000(rule, value, callback) {
  var reg = /(^[0-9]+\.\d+$)|(^[0-9]+$)/;
  if (!reg.test(value)) {
    callback(new Error("请输入非负数"));
  } else {
    if (value > 1000) {
      callback(new Error("请输入1000以内非负数"));
    } else {
      callback();
    }
  }
}
// const validTo1000 = (rule, value, callback) => {
//     let reg = /(^[0-9]+\.\d+$)|(^[0-9]+$)/;
//     if (!reg.test(value)) {
//       callback(new Error("请输入非负数"));
//     } else {
//       if (value > 1000) {
//         callback(new Error("请输入1000以内非负数"));
//       } else {
//         callback();
//       }
//     }
//   };

// 非负整数
function validIntNumber(rule, value, callback) {
  var reg = /^(0|[1-9][0-9]*)$/;
  if (value && !reg.test(value)) {
    callback(new Error("请输入非负整数"));
  } else {
    callback();
  }
}
// 身份证号
function validIdentityCard(rule, value, callback) {
  var reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9X]$/;
  if (value === null || value === "") {
    callback();
  } else {
    if (!reg.test(value)) {
      callback(new Error("身份证号前17位需为数字，最后一位可以是数字或大写字母X"));
    } else {
      callback();
    }
  }
}
// 社会信用代码
function validSocial(rule, value, callback) {
  var reg = /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/;
  if (value === null || value === "") {
    callback();
  } else {
    if (!reg.test(value)) {
      callback(new Error("请输入正确的社会信用代码"));
    } else {
      callback();
    }
  }
}
// 银行卡号
function validBanknum(rule, value, callback) {
  // const reg = /^\d{16}|\d{19}$/
  if (value === null || value === "") {
    callback();
  } else {
    var reg = /^[0-9-]{1,30}$/;
    if (value && !reg.test(value)) {
      callback(new Error("银行卡号限制输入30位以内的数字或 '-' "));
    } else {
      callback();
    }
  }
}
function validWater(rule, value, callback) {
  var reg = /^\d{14}$/;
  if (value && !reg.test(value)) {
    callback(new Error("水表号格式不正确"));
  } else {
    callback();
  }
}
function validName(rule, value, callback) {
  var reg = /^[\u4e00-\u9fa5]{2,10}$/;
  if (value && !reg.test(value)) {
    if (rule.field === "recommender") {
      callback(new Error("推荐人格式不正确"));
    } else {
      callback(new Error("姓名格式不正确"));
    }
  } else {
    callback();
  }
}
function validWord(rule, value, callback) {
  var reg = /^[\u4e00-\u9fa5]{1,30}$/;
  if (!reg.test(value)) {
    callback(new Error("请输入1-30个字符"));
  } else {
    callback();
  }
}
//地址（输入文字才能通过校验）
function validWordAddress(rule, value, callback) {
  var reg = /[\u4e00-\u9fa5]+/;
  if (value === "" || value === null) {
    callback();
  } else {
    if (!reg.test(value)) {
      callback(new Error("请输入正确格式"));
    } else {
      callback();
    }
  }
}
//联系方式（输入非文字才能通过校验）
function validNotZhWords(rule, value, callback) {
  var reg = /[\u4e00-\u9fa5]+/;
  if (value === "" || value === null) {
    callback();
  } else {
    if (reg.test(value)) {
      callback(new Error("请输入正确格式"));
    } else {
      callback();
    }
  }
}
// 名字不限字数（中文||英文）
function validNameChineseOrEnglish(rule, value, callback) {
  var reg = /^(?:[\u4e00-\u9fa5]+|[a-zA-Z]+)$/;
  if (value === "" || value === null) {
    callback();
  } else {
    if (!reg.test(value)) {
      callback(new Error("请输入正确格式"));
    } else {
      callback();
    }
  }
}

// 微信号效验
function validateWeixin(rule, value, callback) {
  var reg = /^[a-zA-Z0-9][a-zA-Z0-9_-]{5,20}$/;
  if (value && !reg.test(value)) {
    callback(new Error("请输入正确的电话/微信号码"));
  } else {
    callback();
  }
}
// 流水号效验
function validateSerialNo(rule, value, callback) {
  var reg = /^[a-zA-Z0-9][a-zA-Z0-9]{1,}$/;
  if (value && !reg.test(value)) {
    callback(new Error("请输入正确格式的流水号"));
  } else {
    callback();
  }
}
//  是否为空格
function validateEmpty(rule, value, callback) {
  var reg = /^\s{1,}$/;
  if (reg.test(value)) {
    callback(new Error("内容不可为空"));
  } else {
    callback();
  }
}
//只能输入字母  （权限标识）
function validateLetter(rule, value, callback) {
  var reg = /^[A-Za-z]*$/;
  if (!reg.test(value)) {
    callback(new Error("请输入英文"));
  } else {
    callback();
  }
}

// 前3后2 不为零不为负
function font3behind2(rule, value, callback) {
  var reg = /^(?!0+(\.0{1,2})?$)([1-9]\d{0,2}|\d{1,3})(\.\d{1,2})?$/;
  if (value === null || value == "") {
    callback();
  } else {
    if (!reg.test(value)) {
      callback(new Error("请输入正确格式数字"));
    } else {
      callback();
    }
  }
}
// 不为负数不为零的前7后2（金额）
function font7behind2(rule, value, callback) {
  var reg = /^(?!0+(\.0{1,2})?$)([1-9]\d{0,6}|\d{1,7})(\.\d{1,2})?$/;
  if (value === null || value == "") {
    callback();
  } else {
    if (!reg.test(value)) {
      callback(new Error("请输入正确格式数字"));
    } else {
      callback();
    }
  }
}
// 不为负数不为零的前8后2（金额）
function font8behind2(rule, value, callback) {
  var reg = /^(?!0\d)\d{1,8}(\.\d{1,2})?$/;
  if (value === null || value == "") {
    callback();
  } else {
    if (!reg.test(value)) {
      callback(new Error("请输入正确格式数字"));
    } else {
      callback();
    }
  }
}
// 不为负数为零的前8后2（金额）
function zeroFront8Behind2(rule, value, callback) {
  var reg = /^([1-9]\d{0,7}|\d{1,8})(\.\d{1,2})?$/;
  if (value === null || value == "") {
    callback();
  } else {
    if (!reg.test(value)) {
      callback(new Error("请输入正确格式数字"));
    } else {
      callback();
    }
  }
}

// 不为负数,不为0，但可以为小数的数字
function noFuNo0(rule, value, callback) {
  var reg = /^(?!0+(\.0{1,})?$)\d+(\.\d+)?$/;
  if (value === null || value == "") {
    callback();
  } else {
    if (!reg.test(value)) {
      callback(new Error("请输入正确格式数字"));
    } else {
      callback();
    }
  }
}
// 英文大写字母
function uppercaseLetters(rule, value, callback) {
  var reg = /^[A-Z]+$/;
  if (!reg.test(value)) {
    callback(new Error("请输入英文大写字母"));
  } else {
    callback();
  }
}

// 校验邮箱
function validEmail1(rule, value, callback) {
  var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (value === null || value == "") {
    callback();
  } else {
    if (!reg.test(value)) {
      callback(new Error("请输入正确邮箱"));
    } else {
      callback();
    }
  }
}

/**
 * 强密码校验
 * 密码由6-16个字符组成，必须同时包含数字、大小写字母和除空格外的特殊字符
 * @param {String} password 密码文本
 * @returns {Boolean} 是否是弱密码
 */
function isWeakPassword(password) {
  // 密码长度要求
  if (password.length < 6) return true;
  if (password.length > 16) return true;

  // 避免常见密码
  var commonPasswords = ["qwert@123", "qwert@111", "qwert@123456", "admin@123", "admin@111", "admin@123456", "password@123", "password@111", "password@123456", "abc@123", "abc@111", "abc@123456"];
  if (commonPasswords.includes(password.toLowerCase())) return true;

  // 避免连续字符和重复字符
  // for (let i = 0; i < password.length - 2; i++) {
  //   if (password.charCodeAt(i) === password.charCodeAt(i + 1) - 1 && password.charCodeAt(i) === password.charCodeAt(i + 2) - 2) { return true; } else if (password[i] === password[i + 1] && password[i] === password[i + 2]) { return true; }
  // }

  // 避免字典单词
  // const dictionary = ['password', 'qwerty', 'monkey', 'letmein'];
  // if (dictionary.includes(password.toLowerCase())) { return true; }

  // 包含字符种类要求
  if (!/[a-z]/.test(password) || !/[A-Z]/.test(password) || !/\d/.test(password) || !/[!@#$%^&*(),.?":{}|<>]/.test(password)) return true;

  // 密码强度合格
  return false;
}

// 校验密码
function validatePassword(rule, value, callback) {
  if (value === null || value == "") {
    callback();
  } else {
    if (isWeakPassword(value)) {
      callback(new Error("密码强度弱，密码由6-16个字符组成，必须同时包含数字、大小写字母和除空格外的特殊字符"));
    } else {
      callback();
    }
  }
}