"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.add = add;
exports.default = void 0;
exports.del = del;
exports.edit = edit;
exports.get = get;
exports.getDictMap = getDictMap;
var _request = _interopRequireDefault(require("@/utils/request"));
function get(blurry) {
  var params = {
    blurry: blurry,
    page: 1
  };
  return (0, _request.default)({
    url: "htapi/htauth/api/uaCenter/dict",
    method: "get",
    params: params
  });
}
// 老字典详情
// export function get(dictName) {
//   const params = {
//     dictName,
//     page: 1,
//     size: 9999
//   }
//   return request({
//     url: "htapi/htauth/api/uaCenter/dictDetail/list",
//     method: "get",
//     params
//   })
// }

function getDictMap(dictName) {
  var params = {
    dictName: dictName,
    page: 0,
    size: 9999
  };
  return (0, _request.default)({
    url: "htapi/htauth/api/uaCenter/dictDetail/map",
    method: "get",
    params: params
  });
}
function add(data) {
  return (0, _request.default)({
    url: "htapi/htauth/api/uaCenter/dictDetail",
    method: "post",
    data: data
  });
}
function del(ids) {
  return (0, _request.default)({
    url: "htapi/htauth/api/uaCenter/dictDetail",
    method: "delete",
    data: ids
  });
}
function edit(data) {
  return (0, _request.default)({
    url: "htapi/htauth/api/uaCenter/dictDetail",
    method: "put",
    data: data
  });
}
var _default = {
  add: add,
  edit: edit,
  del: del
};
exports.default = _default;