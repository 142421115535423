"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _toArray2 = _interopRequireDefault(require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime-corejs2/helpers/toArray"));
var _Dict = _interopRequireDefault(require("./Dict"));
var _store = _interopRequireDefault(require("@/store"));
var install = function install(Vue) {
  Vue.mixin({
    data: function data() {
      if (this.$options.dicts instanceof Array) {
        var dict = {
          dict: {},
          label: {}
        };
        return {
          dict: dict,
          dictDown: false
        };
      }
      return {};
    },
    created: function created() {
      var _this = this;
      this.dictDown = false;
      if (this.$options.dicts instanceof Array) {
        // copy一份namelist从vuex中找出重复name并删除
        var _this$$options$dicts = (0, _toArray2.default)(this.$options.dicts),
          nameList = _this$$options$dicts.slice(0);
        for (var i = nameList.length - 1; i >= 0; i--) {
          if (_store.default.state.dict.dictNames.includes(nameList[i])) {
            nameList.splice(i, 1);
          }
        }
        var res1 = [],
          res2 = [],
          hasNames = [];
        res1.push(nameList.filter(function (item) {
          return !_this.$options.dicts.includes(item);
        }));
        res2.push(this.$options.dicts.filter(function (item) {
          return !nameList.includes(item);
        }));
        hasNames = res1.flat().concat(res2.flat());
        // vuex里面已经有name就使用vuex里的dict
        hasNames.length > 0 && new _Dict.default(this.dict).setDict(hasNames);
        hasNames.length === 0 &&
        // 再把页面上新的name存到vuex里
        this.$options.dicts.forEach(function (item) {
          _store.default.commit("pushDictName", item);
        });
        //新的name发请求去
        nameList.length > 0 && (this.dictDown = true);
        new _Dict.default(this.dict).init(nameList, function () {
          _this.$emit("dictReady");
          _this.dictDown = true;
        });
      }
    }
  });
};
var _default = {
  install: install
};
exports.default = _default;