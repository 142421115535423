"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "ScriptTask",
  props: {
    id: String,
    type: String
  },
  data: function data() {
    return {
      defaultTaskForm: {
        scriptFormat: "",
        script: "",
        resource: "",
        resultVariable: ""
      },
      scriptTaskForm: {}
    };
  },
  watch: {
    id: {
      immediate: true,
      handler: function handler() {
        var _this = this;
        this.bpmnElement = window.bpmnInstances.bpmnElement;
        this.$nextTick(function () {
          return _this.resetTaskForm();
        });
      }
    }
  },
  methods: {
    resetTaskForm: function resetTaskForm() {
      for (var key in this.defaultTaskForm) {
        var _this$bpmnElement;
        var value = ((_this$bpmnElement = this.bpmnElement) === null || _this$bpmnElement === void 0 ? void 0 : _this$bpmnElement.businessObject[key]) || this.defaultTaskForm[key];
        this.$set(this.scriptTaskForm, key, value);
      }
      this.$set(this.scriptTaskForm, "scriptType", this.scriptTaskForm.script ? "inline" : "external");
    },
    updateElementTask: function updateElementTask() {
      var taskAttr = Object.create(null);
      taskAttr.scriptFormat = this.scriptTaskForm.scriptFormat || null;
      taskAttr.resultVariable = this.scriptTaskForm.resultVariable || null;
      if (this.scriptTaskForm.scriptType === "inline") {
        taskAttr.script = this.scriptTaskForm.script || null;
        taskAttr.resource = null;
      } else {
        taskAttr.resource = this.scriptTaskForm.resource || null;
        taskAttr.script = null;
      }
      window.bpmnInstances.modeling.updateProperties(this.bpmnElement, taskAttr);
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.bpmnElement = null;
  }
};
exports.default = _default;