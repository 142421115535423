"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
// 定义全局自定义指令

var selectLoadMore = function selectLoadMore(Vue) {
  // el-select组件数据过多，使用翻页加载数据指令
  Vue.directive('selectLoadMore', {
    bind: function bind(el, binding) {
      var SELECT_WRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
      SELECT_WRAP_DOM.addEventListener('scroll', function () {
        // toFixed：把this.scrollTop转换为整数，兼容不同版本浏览器
        var condition = this.scrollHeight - this.scrollTop.toFixed(0) <= this.clientHeight + 1;
        if (condition) binding.value();
      });
    }
  });
};
if (window.Vue) {
  _vue.default.use(selectLoadMore);
}
var _default = selectLoadMore;
exports.default = _default;