var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-input",
    _vm._b(
      {
        attrs: { type: "number" },
        on: { change: _vm.filterDecimal },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.$slots, function(index, name) {
              return {
                key: name,
                fn: function() {
                  return [_vm._t(name)]
                },
                proxy: true
              }
            })
          ],
          null,
          true
        ),
        model: {
          value: _vm.modelValue,
          callback: function($$v) {
            _vm.modelValue = $$v
          },
          expression: "modelValue"
        }
      },
      "el-input",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }