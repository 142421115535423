"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));
var _auth = require("@/utils/auth");
var _login = require("@/api/login");
var _userRole = require("@/api/business/zx/userRole");
var _vuex = require("vuex");
var _Hamburger = _interopRequireDefault(require("@/components/Hamburger"));
var _Screenfull = _interopRequireDefault(require("@/components/Screenfull"));
var _HeaderSearch = _interopRequireDefault(require("@/components/HeaderSearch"));
var _avatar = _interopRequireDefault(require("@/assets/images/avatar.png"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Hamburger: _Hamburger.default,
    Screenfull: _Screenfull.default,
    Search: _HeaderSearch.default
  },
  data: function data() {
    return {
      nickName: sessionStorage.getItem("name"),
      Avatar: _avatar.default,
      dialogVisible: false,
      userchangeVisible: false,
      rolesArray: [],
      value: "",
      merchantUuid: "",
      tenantId: null
    };
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(["sidebar", "device", "user", "baseApi"])), {}, {
    show: {
      get: function get() {
        return this.$store.state.settings.showSettings;
      },
      set: function set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "showSettings",
          value: val
        });
      }
    }
  }),
  beforeCreate: function beforeCreate() {
    var _this = this;
    (0, _login.getInfo)().then(function (res) {
      // this.merchantUuid = res.user.merchant.merchantUuid;
      // this.tenantId = res.user.tenantId;
      _this.value = res.user.role.id;
      _this.rolesArray = res.user.roles;
    });
  },
  methods: {
    toMyApprove: function toMyApprove() {
      this.$router.push("/oa/approve/list");
    },
    rolesubmit: function rolesubmit() {
      var _this2 = this;
      var data = {
        // merchantUuid: this.merchantUuid,
        // tenantId: this.tenantId,
        roleId: this.value
      };
      (0, _userRole.userRole)(data).then(function (res) {
        (0, _auth.setToken)(res.token);
        _this2.$store.commit("SET_TOKEN", res.token);
        _this2.$router.go(0);
        // this.$EventBus.$emit('refresh','true')
      });
    },
    userchange: function userchange() {
      this.userchangeVisible = true;
    },
    handleClose: function handleClose() {
      var _this3 = this;
      (0, _login.getInfo)().then(function (res) {
        // this.merchantUuid = res.user.merchant.merchantUuid;
        _this3.tenantId = res.user.tenantId;
        _this3.value = res.user.role.id;
        _this3.rolesArray = res.user.roles;
      });
      this.userchangeVisible = false;
    },
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    open: function open() {
      var _this4 = this;
      this.$confirm("确定注销并退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this4.logout();
      });
    },
    logout: function logout() {
      this.$store.dispatch("LogOut").then(function () {
        location.reload();
      });
    }
  }
};
exports.default = _default;