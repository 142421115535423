"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "ElementMultiInstance",
  props: {
    businessObject: Object,
    type: String
  },
  inject: {
    prefix: "prefix"
  },
  data: function data() {
    return {
      loopCharacteristics: "",
      //默认配置，用来覆盖原始不存在的选项，避免报错
      defaultLoopInstanceForm: {
        completionCondition: "",
        loopCardinality: "",
        extensionElements: [],
        asyncAfter: false,
        asyncBefore: false,
        exclusive: false
      },
      loopInstanceForm: {}
    };
  },
  watch: {
    businessObject: {
      immediate: true,
      handler: function handler(val) {
        this.bpmnElement = window.bpmnInstances.bpmnElement;
        this.getElementLoop(val);
      }
    }
  },
  methods: {
    getElementLoop: function getElementLoop(businessObject) {
      var _businessObject$loopC, _businessObject$loopC2, _businessObject$loopC3, _businessObject$loopC4, _businessObject$loopC5, _businessObject$loopC6;
      if (!businessObject.loopCharacteristics) {
        this.loopCharacteristics = "Null";
        this.loopInstanceForm = {};
        return;
      }
      if (businessObject.loopCharacteristics.$type === "bpmn:StandardLoopCharacteristics") {
        this.loopCharacteristics = "StandardLoop";
        this.loopInstanceForm = {};
        return;
      }
      if (businessObject.loopCharacteristics.isSequential) {
        this.loopCharacteristics = "SequentialMultiInstance";
      } else {
        this.loopCharacteristics = "ParallelMultiInstance";
      }
      // 合并配置
      this.loopInstanceForm = _objectSpread(_objectSpread(_objectSpread({}, this.defaultLoopInstanceForm), businessObject.loopCharacteristics), {}, {
        completionCondition: (_businessObject$loopC = (_businessObject$loopC2 = businessObject.loopCharacteristics) === null || _businessObject$loopC2 === void 0 ? void 0 : (_businessObject$loopC3 = _businessObject$loopC2.completionCondition) === null || _businessObject$loopC3 === void 0 ? void 0 : _businessObject$loopC3.body) !== null && _businessObject$loopC !== void 0 ? _businessObject$loopC : "",
        loopCardinality: (_businessObject$loopC4 = (_businessObject$loopC5 = businessObject.loopCharacteristics) === null || _businessObject$loopC5 === void 0 ? void 0 : (_businessObject$loopC6 = _businessObject$loopC5.loopCardinality) === null || _businessObject$loopC6 === void 0 ? void 0 : _businessObject$loopC6.body) !== null && _businessObject$loopC4 !== void 0 ? _businessObject$loopC4 : ""
      });
      // 保留当前元素 businessObject 上的 loopCharacteristics 实例
      this.multiLoopInstance = window.bpmnInstances.bpmnElement.businessObject.loopCharacteristics;
      // 更新表单
      if (businessObject.loopCharacteristics.extensionElements && businessObject.loopCharacteristics.extensionElements.values && businessObject.loopCharacteristics.extensionElements.values.length) {
        this.$set(this.loopInstanceForm, "timeCycle", businessObject.loopCharacteristics.extensionElements.values[0].body);
      }
    },
    changeLoopCharacteristicsType: function changeLoopCharacteristicsType(type) {
      // this.loopInstanceForm = { ...this.defaultLoopInstanceForm }; // 切换类型取消原表单配置
      // 取消多实例配置
      if (type === "Null") {
        window.bpmnInstances.modeling.updateProperties(this.bpmnElement, {
          loopCharacteristics: null
        });
        return;
      }
      // 配置循环
      if (type === "StandardLoop") {
        var loopCharacteristicsObject = window.bpmnInstances.moddle.create("bpmn:StandardLoopCharacteristics");
        window.bpmnInstances.modeling.updateProperties(this.bpmnElement, {
          loopCharacteristics: loopCharacteristicsObject
        });
        this.multiLoopInstance = null;
        return;
      }
      // 时序
      if (type === "SequentialMultiInstance") {
        this.multiLoopInstance = window.bpmnInstances.moddle.create("bpmn:MultiInstanceLoopCharacteristics", {
          isSequential: true
        });
      } else {
        this.multiLoopInstance = window.bpmnInstances.moddle.create("bpmn:MultiInstanceLoopCharacteristics", {
          collection: "${coll_userList}"
        });
      }
      window.bpmnInstances.modeling.updateProperties(this.bpmnElement, {
        loopCharacteristics: this.multiLoopInstance
      });
    },
    // 循环基数
    updateLoopCardinality: function updateLoopCardinality(cardinality) {
      var loopCardinality = null;
      if (cardinality && cardinality.length) {
        loopCardinality = window.bpmnInstances.moddle.create("bpmn:FormalExpression", {
          body: cardinality
        });
      }
      window.bpmnInstances.modeling.updateModdleProperties(this.bpmnElement, this.multiLoopInstance, {
        loopCardinality: loopCardinality
      });
    },
    // 完成条件
    updateLoopCondition: function updateLoopCondition(condition) {
      var completionCondition = null;
      if (condition && condition.length) {
        completionCondition = window.bpmnInstances.moddle.create("bpmn:FormalExpression", {
          body: condition
        });
      }
      window.bpmnInstances.modeling.updateModdleProperties(this.bpmnElement, this.multiLoopInstance, {
        completionCondition: completionCondition
      });
    },
    // 重试周期
    updateLoopTimeCycle: function updateLoopTimeCycle(timeCycle) {
      var extensionElements = window.bpmnInstances.moddle.create("bpmn:ExtensionElements", {
        values: [window.bpmnInstances.moddle.create("".concat(this.prefix, ":FailedJobRetryTimeCycle"), {
          body: timeCycle
        })]
      });
      window.bpmnInstances.modeling.updateModdleProperties(this.bpmnElement, this.multiLoopInstance, {
        extensionElements: extensionElements
      });
    },
    // 直接更新的基础信息
    updateLoopBase: function updateLoopBase() {
      window.bpmnInstances.modeling.updateModdleProperties(this.bpmnElement, this.multiLoopInstance, {
        collection: this.loopInstanceForm.collection || null,
        elementVariable: this.loopInstanceForm.elementVariable || null
      });
    },
    // 各异步状态
    updateLoopAsync: function updateLoopAsync(key) {
      var _this$loopInstanceFor = this.loopInstanceForm,
        asyncBefore = _this$loopInstanceFor.asyncBefore,
        asyncAfter = _this$loopInstanceFor.asyncAfter;
      var asyncAttr = Object.create(null);
      if (!asyncBefore && !asyncAfter) {
        this.$set(this.loopInstanceForm, "exclusive", false);
        asyncAttr = {
          asyncBefore: false,
          asyncAfter: false,
          exclusive: false,
          extensionElements: null
        };
      } else {
        asyncAttr[key] = this.loopInstanceForm[key];
      }
      window.bpmnInstances.modeling.updateModdleProperties(this.bpmnElement, this.multiLoopInstance, asyncAttr);
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.multiLoopInstance = null;
    this.bpmnElement = null;
  }
};
exports.default = _default;