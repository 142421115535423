"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.number.constructor");
var _ElementBaseInfo = _interopRequireDefault(require("./base/ElementBaseInfo"));
var _ElementOtherConfig = _interopRequireDefault(require("./other/ElementOtherConfig"));
var _ElementTask = _interopRequireDefault(require("./task/ElementTask"));
var _ElementMultiInstance = _interopRequireDefault(require("./multi-instance/ElementMultiInstance"));
var _FlowCondition = _interopRequireDefault(require("./flow-condition/FlowCondition"));
var _SignalAndMessage = _interopRequireDefault(require("./signal-message/SignalAndMessage"));
var _ElementListeners = _interopRequireDefault(require("./listeners/ElementListeners"));
var _ElementProperties = _interopRequireDefault(require("./properties/ElementProperties"));
var _ElementForm = _interopRequireDefault(require("./form/ElementForm"));
var _UserTaskListeners = _interopRequireDefault(require("./listeners/UserTaskListeners"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/**
 * 侧边栏
 * @Author MiyueFE
 * @Home https://github.com/miyuesc
 * @Date 2021年3月31日18:57:51
 */
var _default = {
  name: "MyPropertiesPanel",
  components: {
    UserTaskListeners: _UserTaskListeners.default,
    ElementForm: _ElementForm.default,
    ElementProperties: _ElementProperties.default,
    ElementListeners: _ElementListeners.default,
    SignalAndMassage: _SignalAndMessage.default,
    FlowCondition: _FlowCondition.default,
    ElementMultiInstance: _ElementMultiInstance.default,
    ElementTask: _ElementTask.default,
    ElementOtherConfig: _ElementOtherConfig.default,
    ElementBaseInfo: _ElementBaseInfo.default
  },
  componentName: "MyPropertiesPanel",
  props: {
    bpmnModeler: Object,
    prefix: {
      type: String,
      default: "camunda"
    },
    width: {
      type: Number,
      default: 480
    },
    idEditDisabled: {
      type: Boolean,
      default: false
    },
    model: Object // 流程模型的数据
  },
  provide: function provide() {
    return {
      prefix: this.prefix,
      width: this.width
    };
  },
  data: function data() {
    return {
      activeTab: "base",
      elementId: "",
      elementType: "",
      elementBusinessObject: {},
      // 元素 businessObject 镜像，提供给需要做判断的组件使用
      conditionFormVisible: false,
      // 流转条件设置
      formVisible: false // 表单配置
    };
  },

  watch: {
    elementId: {
      handler: function handler() {
        this.activeTab = "base";
      }
    }
  },
  created: function created() {
    this.initModels();
  },
  methods: {
    initModels: function initModels() {
      var _this = this;
      // 初始化 modeler 以及其他 moddle
      if (!this.bpmnModeler) {
        // 避免加载时 流程图 并未加载完成
        this.timer = setTimeout(function () {
          return _this.initModels();
        }, 10);
        return;
      }
      if (this.timer) clearTimeout(this.timer);
      window.bpmnInstances = {
        modeler: this.bpmnModeler,
        modeling: this.bpmnModeler.get("modeling"),
        moddle: this.bpmnModeler.get("moddle"),
        eventBus: this.bpmnModeler.get("eventBus"),
        bpmnFactory: this.bpmnModeler.get("bpmnFactory"),
        elementFactory: this.bpmnModeler.get("elementFactory"),
        elementRegistry: this.bpmnModeler.get("elementRegistry"),
        replace: this.bpmnModeler.get("replace"),
        selection: this.bpmnModeler.get("selection")
      };
      this.getActiveElement();
    },
    getActiveElement: function getActiveElement() {
      var _this2 = this;
      // 初始第一个选中元素 bpmn:Process
      this.initFormOnChanged(null);
      this.bpmnModeler.on("import.done", function (e) {
        _this2.initFormOnChanged(null);
      });
      // 监听选择事件，修改当前激活的元素以及表单
      this.bpmnModeler.on("selection.changed", function (_ref) {
        var newSelection = _ref.newSelection;
        _this2.initFormOnChanged(newSelection[0] || null);
      });
      this.bpmnModeler.on("element.changed", function (_ref2) {
        var element = _ref2.element;
        // 保证 修改 "默认流转路径" 类似需要修改多个元素的事件发生的时候，更新表单的元素与原选中元素不一致。
        if (element && element.id === _this2.elementId) {
          _this2.initFormOnChanged(element);
        }
      });
    },
    // 初始化数据
    initFormOnChanged: function initFormOnChanged(element) {
      var activatedElement = element;
      if (!activatedElement) {
        var _window$bpmnInstances;
        activatedElement = (_window$bpmnInstances = window.bpmnInstances.elementRegistry.find(function (el) {
          return el.type === "bpmn:Process";
        })) !== null && _window$bpmnInstances !== void 0 ? _window$bpmnInstances : window.bpmnInstances.elementRegistry.find(function (el) {
          return el.type === "bpmn:Collaboration";
        });
      }
      if (!activatedElement) return;
      window.bpmnInstances.bpmnElement = activatedElement;
      this.bpmnElement = activatedElement;
      this.elementId = activatedElement.id;
      this.elementType = activatedElement.type.split(":")[1] || "";
      this.elementBusinessObject = JSON.parse(JSON.stringify(activatedElement.businessObject));
      this.conditionFormVisible = !!(this.elementType === "SequenceFlow" && activatedElement.source && activatedElement.source.type.indexOf("StartEvent") === -1);
      this.formVisible = this.elementType === "UserTask" || this.elementType === "StartEvent";
    },
    beforeDestroy: function beforeDestroy() {
      window.bpmnInstances = null;
    }
  }
};
exports.default = _default;