"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _ProcessDesigner = _interopRequireDefault(require("./ProcessDesigner.vue"));
_ProcessDesigner.default.install = function (Vue) {
  Vue.component(_ProcessDesigner.default.name, _ProcessDesigner.default);
};

// 流程图的设计器，可编辑
var _default = _ProcessDesigner.default;
exports.default = _default;