"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _index = _interopRequireDefault(require("./numberInput/index.vue"));
var _vue = _interopRequireDefault(require("vue"));
var arr = [_index.default];
arr.forEach(function (item) {
  _vue.default.component(item.name, item);
});