"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var getters = {
  deployUploadApi: function deployUploadApi(state) {
    return state.api.deployUploadApi;
  },
  databaseUploadApi: function databaseUploadApi(state) {
    return state.api.databaseUploadApi;
  },
  size: function size(state) {
    return state.app.size;
  },
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  token: function token(state) {
    return state.user.token;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  user: function user(state) {
    return state.user.user;
  },
  loadMenus: function loadMenus(state) {
    return state.user.loadMenus;
  },
  permission_routers: function permission_routers(state) {
    return state.permission.routers;
  },
  vueRouter: function vueRouter(state) {
    return state.permission.vueRouter;
  },
  addRouters: function addRouters(state) {
    return state.permission.addRouters;
  },
  socketApi: function socketApi(state) {
    return state.api.socketApi;
  },
  imagesUploadApi: function imagesUploadApi(state) {
    return state.api.imagesUploadApi;
  },
  baseApi: function baseApi(state) {
    return state.api.baseApi;
  },
  fileUploadApi: function fileUploadApi(state) {
    return state.api.fileUploadApi;
  },
  updateAvatarApi: function updateAvatarApi(state) {
    return state.api.updateAvatarApi;
  },
  qiNiuUploadApi: function qiNiuUploadApi(state) {
    return state.api.qiNiuUploadApi;
  },
  sqlApi: function sqlApi(state) {
    return state.api.sqlApi;
  },
  swaggerApi: function swaggerApi(state) {
    return state.api.swaggerApi;
  },
  //图片上传项目
  imgApi: function imgApi(state) {
    return state.api.imgApi;
  },
  imguplod: function imguplod(state) {
    return state.api.imguplod;
  },
  imguplodV2: function imguplodV2(state) {
    return state.api.imguplodV2;
  },
  // 字典
  dicts: function dicts(state) {
    return state.dict.dicts;
  },
  dictNames: function dictNames(state) {
    return state.dict.dictNames;
  },
  depositCreate: function depositCreate(state) {
    return state.deposit.depositCreate;
  },
  itemInfo: function itemInfo(state) {
    return state.itemInfo.itemInfo;
  }
};
var _default = getters;
exports.default = _default;