"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _rsaEncrypt = require("@/utils/rsaEncrypt");
var _settings = _interopRequireDefault(require("@/settings"));
var _login = require("@/api/login");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _menu = require("@/api/system/menu");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Login",
  data: function data() {
    return {
      codeUrl: "",
      cookiePass: "",
      loginForm: {
        username: process.env.NODE_ENV === "dev" ? "mhh" : null,
        password: process.env.NODE_ENV === "dev" ? "Hcyx@2024" : null,
        rememberMe: false,
        code: "",
        codeUuid: ""
      },
      loginRules: {
        username: [{
          required: true,
          trigger: "blur",
          message: "登录名不能为空"
        }],
        password: [{
          required: true,
          trigger: "blur",
          message: "密码不能为空"
        }],
        code: [{
          required: true,
          trigger: "change",
          message: "验证码不能为空"
        }]
      },
      loading: false,
      redirect: undefined,
      log_img: require("../../src/assets/images/login.png"),
      title_img: require("../../src/assets/images/title.png")
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  created: function created() {
    this.getCode();
    this.getCookie();
  },
  methods: {
    getCode: function getCode() {
      var _this = this;
      // getCodeImg().then((res) => {
      //   this.codeUrl = res.result.img;
      //   this.loginForm.codeUuid = res.result.codeUuid;
      // });
      (0, _login.getCodeImg)().then(function (res) {
        _this.codeUrl = res.img;
        _this.loginForm.codeUuid = res.codeUuid;
      });
    },
    getCookie: function getCookie() {
      var username = _jsCookie.default.get("username");
      var password = _jsCookie.default.get("password");
      var rememberMe = _jsCookie.default.get("rememberMe");
      // 保存cookie里面的加密后的密码
      this.cookiePass = password === undefined ? "" : password;
      password = password === undefined ? this.loginForm.password : password;
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password: password,
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
        code: ""
      };
    },
    handleLogin: function handleLogin() {
      var _this2 = this;
      this.$refs.loginForm.validate(function (valid) {
        var user = {
          username: _this2.loginForm.username,
          password: _this2.loginForm.password,
          rememberMe: _this2.loginForm.rememberMe,
          code: _this2.loginForm.code,
          codeUuid: _this2.loginForm.codeUuid
        };
        if (user.password !== _this2.cookiePass) {
          user.password = (0, _rsaEncrypt.encrypt)(user.password);
        }
        if (valid) {
          _this2.loading = true;
          if (user.rememberMe) {
            _jsCookie.default.set("username", user.username, {
              expires: _settings.default.passCookieExpires
            });
            _jsCookie.default.set("password", user.password, {
              expires: _settings.default.passCookieExpires
            });
            _jsCookie.default.set("rememberMe", user.rememberMe, {
              expires: _settings.default.passCookieExpires
            });
          } else {
            _jsCookie.default.remove("username");
            _jsCookie.default.remove("password");
            _jsCookie.default.remove("rememberMe");
          }
          _this2.$store.dispatch("Login", user).then(function () {
            (0, _menu.buildMenus)().then(function (res) {
              console.log(res, res[0].children[0], res[0].children[0], 'resres');
              res.forEach(function (item) {
                item.path = item.path[0] === '/' ? item.path : "/".concat(item.path);
              });
              if (res && res[0] && res[0].children[0] && res[0].children[0].path) {
                var initRouter = "".concat(res[0].path, "/").concat(res[0].children[0].path);
                _this2.$router.push({
                  path: _this2.redirect || initRouter
                });
              } else {
                _this2.$message.warning('当前账号未分配菜单');
              }
            });
          }).catch(function () {
            _this2.loading = false;
            _this2.getCode();
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;