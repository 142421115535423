"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
var _vue = _interopRequireDefault(require("vue"));
var _vueAmap = _interopRequireDefault(require("vue-amap"));
//高德离线地图

_vue.default.use(_vueAmap.default);
_vueAmap.default.initAMapApiLoader({
  //高德key
  // key: 'ed874656da9395f37265c6814a45e7e7',
  key: '20efbd8c852b2a44da4986e54c0ffc29',
  //插件引入
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geocoder', 'AMap.Marker', 'AMap.Geolocation', 'AMap.MouseTool', 'AMap.Text', 'AMap.Polygon', 'AMap.OverlayGroup', 'AMap.LngLat', 'AMap.ImageLayer'],
  v: '1.4.15',
  uiVersion: '1.0.11'
});
window._AMapSecurityConfig = {
  securityJsCode: 'da5f6f527b16251d48421d1cfbad82da'
};

// import VueAMap from '@vuemap/vue-amap'
// import '@vuemap/vue-amap/dist/style.css'
// import VueAMap from 'vue-amap'

// export function initMapApi() {
//   VueAMap.initAMapApiLoader({
//     key: '2d4b95980b27d87da1bea58045bb0e09',
//     securityJsCode: 'c5683746baf98e46961ebcf6117105cb',
//     version: '2.0',
//     uiVersion: '1.0.11',
//     //插件引入
//     plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType',
//       'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geocoder', 'AMap.Marker', 'AMap.Geolocation', 'AMap.MouseTool', 'AMap.Text', 'AMap.Polygon', 'AMap.OverlayGroup', 'AMap.LngLat', 'AMap.ImageLayer'
//     ],
//   })
// }