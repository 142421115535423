"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));
var _UserTask = _interopRequireDefault(require("./task-components/UserTask"));
var _ScriptTask = _interopRequireDefault(require("./task-components/ScriptTask"));
var _ReceiveTask = _interopRequireDefault(require("./task-components/ReceiveTask"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "ElementTaskConfig",
  components: {
    UserTask: _UserTask.default,
    ScriptTask: _ScriptTask.default,
    ReceiveTask: _ReceiveTask.default
  },
  props: {
    id: String,
    type: String
  },
  data: function data() {
    return {
      taskConfigForm: {
        asyncAfter: false,
        asyncBefore: false,
        exclusive: false
      },
      witchTaskComponent: "",
      installedComponent: {
        // 手工任务与普通任务一致，不需要其他配置
        // 接收消息任务，需要在全局下插入新的消息实例，并在该节点下的 messageRef 属性绑定该实例
        // 发送任务、服务任务、业务规则任务共用一个相同配置
        UserTask: "UserTask",
        // 用户任务配置
        ScriptTask: "ScriptTask",
        // 脚本任务配置
        ReceiveTask: "ReceiveTask" // 消息接收任务
      }
    };
  },

  watch: {
    id: {
      immediate: true,
      handler: function handler() {
        var _this$bpmnElement, _this$bpmnElement$bus, _this$bpmnElement2, _this$bpmnElement2$bu, _this$bpmnElement3, _this$bpmnElement3$bu;
        this.bpmnElement = window.bpmnInstances.bpmnElement;
        this.taskConfigForm.asyncBefore = (_this$bpmnElement = this.bpmnElement) === null || _this$bpmnElement === void 0 ? void 0 : (_this$bpmnElement$bus = _this$bpmnElement.businessObject) === null || _this$bpmnElement$bus === void 0 ? void 0 : _this$bpmnElement$bus.asyncBefore;
        this.taskConfigForm.asyncAfter = (_this$bpmnElement2 = this.bpmnElement) === null || _this$bpmnElement2 === void 0 ? void 0 : (_this$bpmnElement2$bu = _this$bpmnElement2.businessObject) === null || _this$bpmnElement2$bu === void 0 ? void 0 : _this$bpmnElement2$bu.asyncAfter;
        this.taskConfigForm.exclusive = (_this$bpmnElement3 = this.bpmnElement) === null || _this$bpmnElement3 === void 0 ? void 0 : (_this$bpmnElement3$bu = _this$bpmnElement3.businessObject) === null || _this$bpmnElement3$bu === void 0 ? void 0 : _this$bpmnElement3$bu.exclusive;
      }
    },
    type: {
      immediate: true,
      handler: function handler() {
        this.witchTaskComponent = this.installedComponent[this.type];
      }
    }
  },
  methods: {
    changeTaskAsync: function changeTaskAsync() {
      if (!this.taskConfigForm.asyncBefore && !this.taskConfigForm.asyncAfter) {
        this.taskConfigForm.exclusive = false;
      }
      window.bpmnInstances.modeling.updateProperties(window.bpmnInstances.bpmnElement, _objectSpread({}, this.taskConfigForm));
    }
  }
};
exports.default = _default;