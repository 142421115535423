var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel-tab__content" },
    [
      _c(
        "el-form",
        {
          attrs: { size: "small", "label-width": "80px" },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "表单标识" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                on: { change: _vm.updateElementFormKey },
                model: {
                  value: _vm.formKey,
                  callback: function($$v) {
                    _vm.formKey = $$v
                  },
                  expression: "formKey"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "业务标识" } },
            [
              _c(
                "el-select",
                {
                  on: { change: _vm.updateElementBusinessKey },
                  model: {
                    value: _vm.businessKey,
                    callback: function($$v) {
                      _vm.businessKey = $$v
                    },
                    expression: "businessKey"
                  }
                },
                [
                  _vm._l(_vm.fieldList, function(i) {
                    return _c("el-option", {
                      key: i.id,
                      attrs: { value: i.id, label: i.label }
                    })
                  }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "无", value: "" } })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "element-property list-property" },
        [
          _c("el-divider", [
            _c("i", { staticClass: "el-icon-coin" }),
            _vm._v(" 表单字段")
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.fieldList,
                size: "small",
                "max-height": "240",
                border: "",
                fit: ""
              }
            },
            [
              _c("el-table-column", {
                attrs: { label: "序号", type: "index", width: "50px" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "字段名称",
                  prop: "label",
                  "min-width": "80px",
                  "show-overflow-tooltip": ""
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "字段类型",
                  prop: "type",
                  "min-width": "80px",
                  formatter: function(row) {
                    return _vm.fieldType[row.type] || row.type
                  },
                  "show-overflow-tooltip": ""
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "默认值",
                  prop: "defaultValue",
                  "min-width": "80px",
                  "show-overflow-tooltip": ""
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "90px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.openFieldForm(row, $index)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c("el-divider", { attrs: { direction: "vertical" } }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: { color: "#ff4d4f" },
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.removeField(row, $index)
                              }
                            }
                          },
                          [_vm._v("移除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "element-drawer__button" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary", icon: "el-icon-plus" },
              on: {
                click: function($event) {
                  return _vm.openFieldForm(null, -1)
                }
              }
            },
            [_vm._v("添加字段")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.fieldModelVisible,
            title: "字段配置",
            size: _vm.width + "px",
            "append-to-body": "",
            "destroy-on-close": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.fieldModelVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.formFieldForm,
                "label-width": "90px",
                size: "small"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "字段ID" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.formFieldForm.id,
                      callback: function($$v) {
                        _vm.$set(_vm.formFieldForm, "id", $$v)
                      },
                      expression: "formFieldForm.id"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "类型" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择字段类型", clearable: "" },
                      on: { change: _vm.changeFieldTypeType },
                      model: {
                        value: _vm.formFieldForm.typeType,
                        callback: function($$v) {
                          _vm.$set(_vm.formFieldForm, "typeType", $$v)
                        },
                        expression: "formFieldForm.typeType"
                      }
                    },
                    _vm._l(_vm.fieldType, function(value, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: value, value: key }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.formFieldForm.typeType === "custom"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "类型名称" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.formFieldForm.type,
                          callback: function($$v) {
                            _vm.$set(_vm.formFieldForm, "type", $$v)
                          },
                          expression: "formFieldForm.type"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "名称" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.formFieldForm.label,
                      callback: function($$v) {
                        _vm.$set(_vm.formFieldForm, "label", $$v)
                      },
                      expression: "formFieldForm.label"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.formFieldForm.typeType === "date"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "时间格式" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.formFieldForm.datePattern,
                          callback: function($$v) {
                            _vm.$set(_vm.formFieldForm, "datePattern", $$v)
                          },
                          expression: "formFieldForm.datePattern"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "默认值" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.formFieldForm.defaultValue,
                      callback: function($$v) {
                        _vm.$set(_vm.formFieldForm, "defaultValue", $$v)
                      },
                      expression: "formFieldForm.defaultValue"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.formFieldForm.type === "enum"
            ? [
                _c("el-divider", { key: "enum-divider" }),
                _vm._v(" "),
                _c(
                  "p",
                  { key: "enum-title", staticClass: "listener-filed__title" },
                  [
                    _c("span", [
                      _c("i", { staticClass: "el-icon-menu" }),
                      _vm._v("枚举值列表：")
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.openFieldOptionForm(null, -1, "enum")
                          }
                        }
                      },
                      [_vm._v("添加枚举值")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    key: "enum-table",
                    attrs: {
                      data: _vm.fieldEnumList,
                      size: "small",
                      "max-height": "240",
                      border: "",
                      fit: ""
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "序号", width: "50px", type: "index" }
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "枚举值编号",
                        prop: "id",
                        "min-width": "100px",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "枚举值名称",
                        prop: "name",
                        "min-width": "100px",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "操作", width: "90px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              var $index = ref.$index
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small", type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.openFieldOptionForm(
                                          row,
                                          $index,
                                          "enum"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _vm._v(" "),
                                _c("el-divider", {
                                  attrs: { direction: "vertical" }
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { color: "#ff4d4f" },
                                    attrs: { size: "small", type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.removeFieldOptionItem(
                                          row,
                                          $index,
                                          "enum"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("移除")]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3692252443
                      )
                    })
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("el-divider", { key: "validation-divider" }),
          _vm._v(" "),
          _c(
            "p",
            { key: "validation-title", staticClass: "listener-filed__title" },
            [
              _c("span", [
                _c("i", { staticClass: "el-icon-menu" }),
                _vm._v("约束条件列表：")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.openFieldOptionForm(null, -1, "constraint")
                    }
                  }
                },
                [_vm._v("添加约束")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              key: "validation-table",
              attrs: {
                data: _vm.fieldConstraintsList,
                size: "small",
                "max-height": "240",
                border: "",
                fit: ""
              }
            },
            [
              _c("el-table-column", {
                attrs: { label: "序号", width: "50px", type: "index" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "约束名称",
                  prop: "name",
                  "min-width": "100px",
                  "show-overflow-tooltip": ""
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "约束配置",
                  prop: "config",
                  "min-width": "100px",
                  "show-overflow-tooltip": ""
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "90px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.openFieldOptionForm(
                                  row,
                                  $index,
                                  "constraint"
                                )
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c("el-divider", { attrs: { direction: "vertical" } }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: { color: "#ff4d4f" },
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.removeFieldOptionItem(
                                  row,
                                  $index,
                                  "constraint"
                                )
                              }
                            }
                          },
                          [_vm._v("移除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider", { key: "property-divider" }),
          _vm._v(" "),
          _c(
            "p",
            { key: "property-title", staticClass: "listener-filed__title" },
            [
              _c("span", [
                _c("i", { staticClass: "el-icon-menu" }),
                _vm._v("字段属性列表：")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.openFieldOptionForm(null, -1, "property")
                    }
                  }
                },
                [_vm._v("添加属性")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              key: "property-table",
              attrs: {
                data: _vm.fieldPropertiesList,
                size: "small",
                "max-height": "240",
                border: "",
                fit: ""
              }
            },
            [
              _c("el-table-column", {
                attrs: { label: "序号", width: "50px", type: "index" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "属性编号",
                  prop: "id",
                  "min-width": "100px",
                  "show-overflow-tooltip": ""
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "属性值",
                  prop: "value",
                  "min-width": "100px",
                  "show-overflow-tooltip": ""
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "90px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.openFieldOptionForm(
                                  row,
                                  $index,
                                  "property"
                                )
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c("el-divider", { attrs: { direction: "vertical" } }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: { color: "#ff4d4f" },
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.removeFieldOptionItem(
                                  row,
                                  $index,
                                  "property"
                                )
                              }
                            }
                          },
                          [_vm._v("移除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "element-drawer__button" },
            [
              _c("el-button", { attrs: { size: "small" } }, [_vm._v("取 消")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.saveField }
                },
                [_vm._v("保 存")]
              )
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.fieldOptionModelVisible,
            title: _vm.optionModelTitle,
            width: "600px",
            "append-to-body": "",
            "destroy-on-close": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.fieldOptionModelVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.fieldOptionForm,
                size: "small",
                "label-width": "96px"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _vm.fieldOptionType !== "constraint"
                ? _c(
                    "el-form-item",
                    { key: "option-id", attrs: { label: "编号/ID" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.fieldOptionForm.id,
                          callback: function($$v) {
                            _vm.$set(_vm.fieldOptionForm, "id", $$v)
                          },
                          expression: "fieldOptionForm.id"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.fieldOptionType !== "property"
                ? _c(
                    "el-form-item",
                    { key: "option-name", attrs: { label: "名称" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.fieldOptionForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.fieldOptionForm, "name", $$v)
                          },
                          expression: "fieldOptionForm.name"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.fieldOptionType === "constraint"
                ? _c(
                    "el-form-item",
                    { key: "option-config", attrs: { label: "配置" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.fieldOptionForm.config,
                          callback: function($$v) {
                            _vm.$set(_vm.fieldOptionForm, "config", $$v)
                          },
                          expression: "fieldOptionForm.config"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.fieldOptionType === "property"
                ? _c(
                    "el-form-item",
                    { key: "option-value", attrs: { label: "值" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.fieldOptionForm.value,
                          callback: function($$v) {
                            _vm.$set(_vm.fieldOptionForm, "value", $$v)
                          },
                          expression: "fieldOptionForm.value"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      _vm.fieldOptionModelVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.saveFieldOption }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }