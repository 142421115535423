"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "NumberInput",
  props: {
    value: {
      type: [Number, String],
      default: function _default() {
        return 0;
      }
    },
    max: {
      type: Number,
      default: function _default() {
        return 9999999;
      }
    },
    min: {
      type: Number,
      default: function _default() {
        return 0;
      }
    },
    toFixed: {
      type: Number,
      default: function _default() {
        return 2;
      }
    }
  },
  computed: {
    modelValue: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        this.$emit("input", val);
      }
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    filterDecimal: function filterDecimal(val) {
      if (val === "") return;
      var temp = Number(val) || 0;
      if (temp > this.max) {
        temp = this.max;
      }
      if (temp < this.min) {
        temp = this.min;
      }
      console.log(temp, this.toFixed);
      this.modelValue = Number(temp.toFixed(this.toFixed));
    }
  }
};
exports.default = _default2;