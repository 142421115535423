"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _elementUi = require("element-ui");
var _utils = require("@/utils");
var TablePathed = {
  extends: _elementUi.Table,
  data: function data() {
    return {};
  },
  mounted: function mounted() {
    var _this = this;
    var reSetTable = (0, _utils.debounce)(function () {
      _this.doLayout();
    }, 100);
    window.addEventListener("resize", reSetTable);
  }
};
var _default = {
  install: function install(Vue) {
    Vue.component(_elementUi.Table.name, TablePathed);
  }
};
exports.default = _default;