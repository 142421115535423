"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.userRole = userRole;
var _request = _interopRequireDefault(require("@/utils/request"));
function userRole(params) {
  return (0, _request.default)({
    url: "htapi/htauth/auth/change/role",
    method: 'get',
    params: params
  });
}
var _default = {
  userRole: userRole
};
exports.default = _default;