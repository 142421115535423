"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var deposit = {
  state: {
    // 定金详情含有的合同基本信息
    depositCreate: {}
  },
  mutations: {
    SET_depositCreate: function SET_depositCreate(state, depositCreate) {
      state.depositCreate = depositCreate;
    }
  },
  actions: {
    saveDeposit: function saveDeposit(_ref) {
      var commit = _ref.commit;
      commit('SET_depositCreate');
    }
  }
};
var _default = deposit;
exports.default = _default;