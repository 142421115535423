"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fieldType = exports.eventType = void 0;
exports.initListenerForm = initListenerForm;
exports.initListenerType = initListenerType;
exports.listenerType = void 0;
require("core-js/modules/es6.regexp.replace");
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
// 初始化表单数据
function initListenerForm(listener) {
  var self = _objectSpread({}, listener);
  if (listener.script) {
    self = _objectSpread(_objectSpread(_objectSpread({}, listener), listener.script), {}, {
      scriptType: listener.script.resource ? "externalScript" : "inlineScript"
    });
  }
  if (listener.event === "timeout" && listener.eventDefinitions) {
    if (listener.eventDefinitions.length) {
      var k = "";
      for (var key in listener.eventDefinitions[0]) {
        if (key.indexOf("time") !== -1) {
          k = key;
          self.eventDefinitionType = key.replace("time", "").toLowerCase();
        }
      }
      self.eventTimeDefinitions = listener.eventDefinitions[0][k].body;
    }
  }
  return self;
}
function initListenerType(listener) {
  var _listener$script;
  var listenerType;
  if (listener.class) listenerType = "classListener";
  if (listener.expression) listenerType = "expressionListener";
  if (listener.delegateExpression) listenerType = "delegateExpressionListener";
  if (listener.script) listenerType = "scriptListener";
  return _objectSpread(_objectSpread(_objectSpread({}, JSON.parse(JSON.stringify(listener))), (_listener$script = listener.script) !== null && _listener$script !== void 0 ? _listener$script : {}), {}, {
    listenerType: listenerType
  });
}
var listenerType = {
  classListener: "Java 类",
  expressionListener: "表达式",
  delegateExpressionListener: "代理表达式",
  scriptListener: "脚本"
};
exports.listenerType = listenerType;
var eventType = {
  create: "创建",
  assignment: "指派",
  complete: "完成",
  delete: "删除",
  update: "更新",
  timeout: "超时"
};
exports.eventType = eventType;
var fieldType = {
  string: "字符串",
  expression: "表达式"
};
exports.fieldType = fieldType;