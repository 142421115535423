"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/typeof");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
var _mavonEditor = _interopRequireDefault(require("mavon-editor"));
require("mavon-editor/dist/css/index.css");
require("./plugins/aMap.js");
var _Dict = _interopRequireDefault(require("./components/Dict"));
var _Permission = _interopRequireDefault(require("./components/Permission"));
require("./assets/styles/element-variables.scss");
require("./assets/styles/index.scss");
var _vueHighlightjs = _interopRequireDefault(require("vue-highlightjs"));
require("highlight.js/styles/atom-one-dark.css");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _routers = _interopRequireDefault(require("./router/routers"));
require("./assets/icons");
require("./router/index");
require("@/components/index.js");
var filters = _interopRequireWildcard(require("@/utils/filter"));
var _vueAwesomeSwiper = _interopRequireDefault(require("vue-awesome-swiper"));
require("swiper/css/swiper.css");
var _elOverlayAutoClose = _interopRequireDefault(require("@/components/el-overlay-auto-close.js"));
var _elTableDoLayout = _interopRequireDefault(require("@/components/el-table-doLayout.js"));
var _elTabsHeaderSlot = _interopRequireDefault(require("@/components/el-tabs-header-slot.js"));
var _index5 = _interopRequireDefault(require("@/components/zxEmpty/index.vue"));
var _selectLoadMore = _interopRequireDefault(require("@/components/search/select/select-load-more.js"));
var _copyText = _interopRequireDefault(require("@/utils/copyText.js"));
var _index6 = _interopRequireDefault(require("@/components/bpmnProcessDesigner/package/index.js"));
require("@/components/bpmnProcessDesigner/package/theme/index.scss");
require("bpmn-js/dist/assets/diagram-js.css");
require("bpmn-js/dist/assets/bpmn-font/css/bpmn.css");
require("bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css");
require("bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
//

//高德离线地图

// 数据字典

// 权限指令

// global css

// 代码高亮

// icon
// permission control

// 轮播图组件

// websocket
// import SocketIO from "vue-socket.io"
// import ClientSocketIO from "socket.io-client"
// import VueSimpleWebSocket from "vue-simple-websocket"
// 空状态组件
// import TableEmpty from "@/components/tableEmpty/index.vue"
//引入
//引入
// bpmnProcessDesigner 需要引入
_vue.default.use(_index6.default);
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});

// 全局修改默认配置，点击空白处不能关闭弹窗
_elementUi.default.Dialog.props.closeOnClickModal.default = false;
// 全局修改默认配置，按下ESC不能关闭弹窗
_elementUi.default.Dialog.props.closeOnPressEscape.default = false;
_vue.default.component("ZxEmpty", _index5.default);
_vue.default.use(_vueHighlightjs.default);
_vue.default.use(_mavonEditor.default);
_vue.default.use(_Permission.default);
_vue.default.use(_Dict.default);
_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get("size") || "small" // set element-ui default size
});

_vue.default.use(_elOverlayAutoClose.default);
_vue.default.use(_elTableDoLayout.default);
_vue.default.use(_elTabsHeaderSlot.default);
// Vue.use(elTableEmpty)
// Vue.use(
//   new SocketIO({
//     debug: false,//开启调试模式
//     connection: ClientSocketIO.connect(process.env.VUE_APP_WS_API, {
//       transports: ["websocket"],//默认使用的请求方式
//       autoConnect: false,//是否自动连接
//     }),
//   })
// )
var ws = window.location.protocol === "https:" ? "wss" : "ws";
var wsUrl = process.env.NODE_ENV === "dev" ? process.env.VUE_APP_WS_API : "".concat(ws, "://").concat(location.host, "/ws");
// Vue.use(VueSimpleWebSocket, wsUrl, {
//   reconnectEnabled: true,
//   reconnectInterval: 5000 // time to reconnect in milliseconds
// });

//刷新
_vue.default.prototype.$EventBus = new _vue.default();
_vue.default.config.productionTip = false;
_vue.default.use(_vueAwesomeSwiper.default);
_vue.default.use(_selectLoadMore.default); // 使用自定义指令
_vue.default.use(_copyText.default); // 使用自定义指令
new _vue.default({
  el: "#app",
  router: _routers.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});