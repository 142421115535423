"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.upload = upload;
var _request = _interopRequireDefault(require("@/utils/request"));
function upload(url, data) {
  return (0, _request.default)({
    url: url,
    method: 'post',
    data: data
  });
}