"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));
require("core-js/modules/web.dom.iterable");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "SignalAndMassage",
  data: function data() {
    return {
      signalList: [],
      messageList: [],
      modelVisible: false,
      modelType: "",
      modelObjectForm: {}
    };
  },
  computed: {
    modelConfig: function modelConfig() {
      if (this.modelType === "message") {
        return {
          title: "创建消息",
          idLabel: "消息ID",
          nameLabel: "消息名称"
        };
      } else {
        return {
          title: "创建信号",
          idLabel: "信号ID",
          nameLabel: "信号名称"
        };
      }
    }
  },
  mounted: function mounted() {
    this.initDataList();
  },
  methods: {
    initDataList: function initDataList() {
      var _this = this;
      this.rootElements = window.bpmnInstances.modeler.getDefinitions().rootElements;
      this.messageIdMap = {};
      this.signalIdMap = {};
      this.messageList = [];
      this.signalList = [];
      this.rootElements.forEach(function (el) {
        if (el.$type === "bpmn:Message") {
          _this.messageIdMap[el.id] = true;
          _this.messageList.push(_objectSpread({}, el));
        }
        if (el.$type === "bpmn:Signal") {
          _this.signalIdMap[el.id] = true;
          _this.signalList.push(_objectSpread({}, el));
        }
      });
    },
    openModel: function openModel(type) {
      this.modelType = type;
      this.modelObjectForm = {};
      this.modelVisible = true;
    },
    addNewObject: function addNewObject() {
      if (this.modelType === "message") {
        if (this.messageIdMap[this.modelObjectForm.id]) {
          return this.$message.error("该消息已存在，请修改id后重新保存");
        }
        var messageRef = window.bpmnInstances.moddle.create("bpmn:Message", this.modelObjectForm);
        this.rootElements.push(messageRef);
      } else {
        if (this.signalIdMap[this.modelObjectForm.id]) {
          return this.$message.error("该信号已存在，请修改id后重新保存");
        }
        var signalRef = window.bpmnInstances.moddle.create("bpmn:Signal", this.modelObjectForm);
        this.rootElements.push(signalRef);
      }
      this.modelVisible = false;
      this.initDataList();
    }
  }
};
exports.default = _default;