var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "zl-main-container" }, [
    _c("div", { staticClass: "zl-min-container bg-white" }, [
      _c("div", { staticClass: "base_card fcenter" }, [
        _c(
          "div",
          { staticClass: "avatar" },
          [
            _c(
              "el-upload",
              {
                ref: "uploadAvatarRef",
                attrs: {
                  action: "",
                  "auto-upload": false,
                  limit: 1,
                  "on-change": _vm.editAvatar,
                  "show-file-list": false
                }
              },
              [
                _vm.avatar
                  ? _c("el-avatar", {
                      staticStyle: { width: "109px", height: "109px" },
                      attrs: { src: _vm.avatar, fit: "cover" }
                    })
                  : _c("div", { staticClass: "avatar_replace ccenter" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.user.nickName.slice(-2)) +
                          "\n          "
                      )
                    ])
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "base_info_container" }, [
          _c("div", { staticClass: "grid_base_info_container" }, [
            _c("div", { staticClass: "base_info_item" }, [
              _c("div", { staticClass: "base_info_item_label" }, [
                _vm._v("真实姓名")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "base_info_item_value" }, [
                _vm._v(_vm._s(_vm.user.nickName))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "base_info_item" }, [
              _c("div", { staticClass: "base_info_item_label" }, [
                _vm._v("部门/岗位")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "base_info_item_value" }, [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.user.dept && _vm.user.dept.name) +
                    " /\n              " +
                    _vm._s(_vm.user.job && _vm.user.job.name) +
                    "\n            "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "base_info_item" }, [
              _c("div", { staticClass: "base_info_item_label" }, [
                _vm._v("工号")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "base_info_item_value" }, [
                _vm._v(_vm._s(_vm.user.jobNumber))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "base_info_item" }, [
              _c("div", { staticClass: "base_info_item_label" }, [
                _vm._v("角色")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "base_info_item_value" }, [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.user.role && _vm.user.role.name) +
                    "\n            "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "base_info_item" }, [
              _c("div", { staticClass: "base_info_item_label" }, [
                _vm._v("登录名")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "base_info_item_value" }, [
                _vm._v(_vm._s(_vm.user.username))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "base_info_item" }, [
              _c("div", { staticClass: "base_info_item_label" }, [
                _vm._v("所属企业")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "base_info_item_value" }, [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.user.companyName || "-") +
                    "\n            "
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "grey_bar" }),
      _vm._v(" "),
      _c("div", { staticClass: "edit_base_card" }, [
        _c("div", { staticClass: "edit_base_card_title_container fcenter" }, [
          _c("div", { staticClass: "edit_base_card_title" }, [
            _vm._v("用户资料")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn_area mla" },
            [
              !_vm.editing
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          _vm.editing = true
                        }
                      }
                    },
                    [_vm._v("修改")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { type: "text", loading: _vm.saveLoading },
                      on: { click: _vm.doSubmit }
                    },
                    [_vm._v("保存")]
                  )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "edit_form_container" },
          [
            _c(
              "el-form",
              {
                ref: "editBaseInfoForm",
                attrs: {
                  model: _vm.editBaseInfoForm,
                  rules: _vm.rules,
                  "label-width": "80px"
                }
              },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 144 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "邮箱", prop: "email" } },
                          [
                            _vm.editing
                              ? _c("el-input", {
                                  model: {
                                    value: _vm.editBaseInfoForm.email,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.editBaseInfoForm,
                                        "email",
                                        $$v
                                      )
                                    },
                                    expression: "editBaseInfoForm.email"
                                  }
                                })
                              : _c("div", [
                                  _vm._v(_vm._s(_vm.editBaseInfoForm.email))
                                ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "手机号码", prop: "phone" } },
                          [
                            _vm.editing
                              ? _c("el-input", {
                                  model: {
                                    value: _vm.editBaseInfoForm.phone,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.editBaseInfoForm,
                                        "phone",
                                        $$v
                                      )
                                    },
                                    expression: "editBaseInfoForm.phone"
                                  }
                                })
                              : _c("div", [
                                  _vm._v(_vm._s(_vm.editBaseInfoForm.phone))
                                ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "性别", prop: "sex" } },
                          [
                            _vm.editing
                              ? _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.editBaseInfoForm.sex,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editBaseInfoForm,
                                          "sex",
                                          $$v
                                        )
                                      },
                                      expression: "editBaseInfoForm.sex"
                                    }
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: "男" } }),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: "女" } })
                                  ],
                                  1
                                )
                              : _c("div", [
                                  _vm._v(_vm._s(_vm.editBaseInfoForm.sex))
                                ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "grey_bar" }),
      _vm._v(" "),
      _c("div", { staticClass: "password_card" }, [
        _c("div", { staticClass: "password_card_title" }, [_vm._v("安全设置")]),
        _vm._v(" "),
        _c("div", { staticClass: "edit_psw_container df" }, [
          _c("div", { staticClass: "edit_psw_form_title" }, [
            _vm._v("登录密码")
          ]),
          _vm._v(" "),
          _vm.editPswIng
            ? _c(
                "div",
                { staticClass: "edit_psw_form_container df fdc" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "editPasswordForm",
                      attrs: {
                        rules: _vm.rules,
                        model: _vm.editPasswordForm,
                        "label-width": "110px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "原密码", prop: "oldPass" } },
                        [
                          _c("el-input", {
                            ref: "oldPassRef",
                            staticStyle: { width: "400px" },
                            attrs: {
                              placeholder: "请输入原密码",
                              type: "password",
                              "auto-complete": "on"
                            },
                            model: {
                              value: _vm.editPasswordForm.oldPass,
                              callback: function($$v) {
                                _vm.$set(_vm.editPasswordForm, "oldPass", $$v)
                              },
                              expression: "editPasswordForm.oldPass"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "新密码", prop: "newPass" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              placeholder: "请输入新密码",
                              type: "password",
                              "auto-complete": "on"
                            },
                            model: {
                              value: _vm.editPasswordForm.newPass,
                              callback: function($$v) {
                                _vm.$set(_vm.editPasswordForm, "newPass", $$v)
                              },
                              expression: "editPasswordForm.newPass"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "确认密码", prop: "confirmPass" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              placeholder: "再次填写确认",
                              type: "password",
                              "auto-complete": "on"
                            },
                            model: {
                              value: _vm.editPasswordForm.confirmPass,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.editPasswordForm,
                                  "confirmPass",
                                  $$v
                                )
                              },
                              expression: "editPasswordForm.confirmPass"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c("div", { staticClass: "edit_psw_tip_container" }, [
                _vm._v(
                  "\n          已设置。密码由6-16个字符组成，必须同时包含数字、大小写字母和除空格外的特殊字符\n        "
                )
              ]),
          _vm._v(" "),
          _vm.editPswIng
            ? _c(
                "div",
                { staticClass: "save_btn mla" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: { click: _vm.confirmEditPsw }
                    },
                    [_vm._v("确认")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          _vm.editPswIng = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  )
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "mla" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          _vm.editPswIng = true
                        }
                      }
                    },
                    [_vm._v("修改")]
                  )
                ],
                1
              )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }