"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _PropertiesPanel = _interopRequireDefault(require("./PropertiesPanel.vue"));
_PropertiesPanel.default.install = function (Vue) {
  Vue.component(_PropertiesPanel.default.name, _PropertiesPanel.default);
};
var _default = _PropertiesPanel.default;
exports.default = _default;