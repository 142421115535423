"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));
var _babelHelperVueJsxMergeProps = _interopRequireDefault(require("@vue/babel-helper-vue-jsx-merge-props"));
var _elementUi = require("element-ui");
var TabsPathed = {
  extends: _elementUi.Tabs,
  data: function data() {
    return {};
  },
  render: function render(h) {
    var _ref;
    var type = this.type,
      handleTabClick = this.handleTabClick,
      handleTabRemove = this.handleTabRemove,
      handleTabAdd = this.handleTabAdd,
      currentName = this.currentName,
      panes = this.panes,
      editable = this.editable,
      addable = this.addable,
      tabPosition = this.tabPosition,
      stretch = this.stretch;
    var newButton = editable || addable ? h("span", {
      "class": "el-tabs__new-tab",
      "on": {
        "click": handleTabAdd,
        "keydown": function keydown(ev) {
          if (ev.keyCode === 13) {
            handleTabAdd();
          }
        }
      },
      "attrs": {
        "tabindex": "0"
      }
    }, [h("i", {
      "class": "el-icon-plus"
    })]) : null;
    var navData = {
      props: {
        currentName: currentName,
        onTabClick: handleTabClick,
        onTabRemove: handleTabRemove,
        editable: editable,
        type: type,
        panes: panes,
        stretch: stretch
      },
      ref: 'nav'
    };
    var header = h("div", {
      "class": ['el-tabs__header', "is-".concat(tabPosition), 'fcenter']
    }, [newButton, h("tab-nav", (0, _babelHelperVueJsxMergeProps.default)([{}, navData, {
      "style": "flex: 1;"
    }])), h("div", {
      "class": "zx_tabs_slot_header mla"
    }, [this.$slots.header])]);
    var panels = h("div", {
      "class": "el-tabs__content"
    }, [this.$slots.default]);
    return h("div", {
      "class": (_ref = {
        'el-tabs': true,
        'el-tabs--card': type === 'card'
      }, (0, _defineProperty2.default)(_ref, "el-tabs--".concat(tabPosition), true), (0, _defineProperty2.default)(_ref, 'el-tabs--border-card', type === 'border-card'), _ref)
    }, [tabPosition !== 'bottom' ? [header, panels] : [panels, header]]);
  }
};
var _default = {
  install: function install(Vue) {
    Vue.component(_elementUi.Tabs.name, TabsPathed);
  }
};
exports.default = _default;