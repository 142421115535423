"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ = _interopRequireDefault(require("@/assets/401_images/401.gif"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Page401",
  data: function data() {
    return {
      errGif: _.default + "?" + +new Date()
    };
  },
  methods: {
    back: function back() {
      if (this.$route.query.noGoBack) {
        this.$router.push({
          path: "/dashboard"
        });
      } else {
        this.$router.go(-1);
      }
    }
  }
};
exports.default = _default;