"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _default = {
  created: function created() {
    var _this$$route = this.$route,
      params = _this$$route.params,
      query = _this$$route.query;
    var path = params.path;
    this.$router.replace({
      path: "/" + path,
      query: query
    });
  },
  render: function render(h) {
    return h(); // avoid warning message
  }
};
exports.default = _default;