"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addClass = addClass;
exports.beautifierConf = void 0;
exports.byteLength = byteLength;
exports.cleanArray = cleanArray;
exports.createUniqueString = createUniqueString;
exports.debounce = debounce;
exports.deepClone = deepClone;
exports.downloadFile = downloadFile;
exports.equals = equals;
exports.exportDefault = void 0;
exports.formatTime = formatTime;
exports.getProp = getProp;
exports.getQueryObject = getQueryObject;
exports.getRoomStr = getRoomStr;
exports.getTime = getTime;
exports.handleTree = handleTree;
exports.hasClass = hasClass;
exports.html2Text = html2Text;
exports.isNumberStr = isNumberStr;
exports.isObject = isObject;
exports.monthEndTime = monthEndTime;
exports.objectMerge = objectMerge;
exports.param = param;
exports.param2Obj = param2Obj;
exports.parseTime = parseTime;
exports.regEmail = regEmail;
exports.regMobile = regMobile;
exports.removeClass = removeClass;
exports.set16 = set16;
exports.setRgb = setRgb;
exports.titleCase = titleCase;
exports.toggleClass = toggleClass;
exports.treeFlat = treeFlat;
exports.uniqueArr = uniqueArr;
require("core-js/modules/es6.function.name");
var _objectWithoutProperties2 = _interopRequireDefault(require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime-corejs2/helpers/objectWithoutProperties"));
require("core-js/modules/es6.object.is");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.set");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.to-string");
var _typeof2 = _interopRequireDefault(require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime-corejs2/helpers/typeof"));
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  var format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  var date;
  if (typeof time === "undefined" || time === null || time === "null") {
    return "";
  } else if ((0, _typeof2.default)(time) === "object") {
    date = time;
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
function formatTime(time, option) {
  if (("" + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;
  if (diff < 30) {
    return "刚刚";
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + "分钟前";
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + "小时前";
  } else if (diff < 3600 * 24 * 2) {
    return "1天前";
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + "月" + d.getDate() + "日" + d.getHours() + "时" + d.getMinutes() + "分";
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  var search = url.substring(url.lastIndexOf("?") + 1);
  var obj = {};
  var reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, function (rs, $1, $2) {
    var name = decodeURIComponent($1);
    var val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
function byteLength(str) {
  // returns the byte length of an utf8 string
  var s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    var code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xdc00 && code <= 0xdfff) i--;
  }
  return s;
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
function cleanArray(actual) {
  var newArray = [];
  for (var i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

/**
 * @param {Object} json
 * @returns {Array}
 */
function param(json) {
  if (!json) return "";
  return cleanArray(Object.keys(json).map(function (key) {
    if (json[key] === undefined) return "";
    return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
  })).join("&");
}

/**
 * @param {string} url
 * @returns {Object}
 */
function param2Obj(url) {
  var search = url.split("?")[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, " ") + '"}');
}

/**
 * @param {string} val
 * @returns {string}
 */
function html2Text(val) {
  var div = document.createElement("div");
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
function objectMerge(target, source) {
  if ((0, _typeof2.default)(target) !== "object") {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach(function (property) {
    var sourceProperty = source[property];
    if ((0, _typeof2.default)(sourceProperty) === "object") {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  var classString = element.className;
  var nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += "" + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}

/**
 * @param {string} type
 * @returns {Date}
 */
function getTime(type) {
  if (type === "start") {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
function debounce(func, wait, immediate) {
  var timeout, args, context, timestamp, result;
  var later = function later() {
    // 据上一次触发时间间隔
    var last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    context = this;
    timestamp = +new Date();
    var callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }
    return result;
  };
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
function deepClone(source) {
  if (!source && (0, _typeof2.default)(source) !== "object") {
    throw new Error("error arguments", "deepClone");
  }
  var targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(function (keys) {
    if (source[keys] && (0, _typeof2.default)(source[keys]) === "object") {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
function uniqueArr(arr) {
  return Array.from(new Set(arr));
}

/**
 * @returns {string}
 */
function createUniqueString() {
  var timestamp = +new Date() + "";
  var randomNum = parseInt((1 + Math.random()) * 65536) + "";
  return (+(randomNum + timestamp)).toString(32);
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp("(\\s|^)" + cls + "(\\s|$)"));
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += " " + cls;
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
    ele.className = ele.className.replace(reg, " ");
  }
}

// 替换邮箱字符
function regEmail(email) {
  var new_email = "";
  if (String(email).indexOf("@") > 0) {
    var str = email.split("@");
    var _s = "";
    if (str[0].length > 3) {
      for (var i = 0; i < str[0].length - 3; i++) {
        _s += "*";
      }
    }
    new_email = str[0].substr(0, 3) + _s + "@" + str[1];
  }
  return new_email;
}

// 替换手机字符
function regMobile(mobile) {
  var new_mobile = "";
  if (mobile.length > 7) {
    new_mobile = mobile.substr(0, 3) + "****" + mobile.substr(7);
  }
  return new_mobile;
}

// 下载文件
function downloadFile(obj, name, suffix) {
  var url = window.URL.createObjectURL(new Blob([obj]));
  var link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  var fileName = parseTime(new Date(), "{y}-{m}-{d}") + "-" + name + "." + suffix;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

//zl新加

function monthEndTime() {
  var nowDate = new Date();
  var date = {
    year: nowDate.getFullYear(),
    month: nowDate.getMonth() + 1,
    date: nowDate.getDate()
  };
  return date.year + "-" + date.month;
}
//rgb颜色转换16
function setRgb(str) {
  var reg = /^(rgb|RGB)/;
  if (!reg.test(str)) {
    return;
  }
  var arr = str.slice(4, str.length - 1).split(",");
  var color = "#";
  for (var i = 0; i < arr.length; i++) {
    var t = Number(arr[i]).toString(16);
    if (t == "0") {
      //如果为“0”的话，需要补0操作,否则只有5位数
      t = t + "0";
    }
    color += t;
  }
  return color;
}
// 16转换成rgb
function set16(str) {
  var reg = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/;
  if (!reg.test(str)) {
    return;
  }
  var newStr = str.toLowerCase().replace(/\#/g, "");
  var len = newStr.length;
  if (len == 3) {
    var t = "";
    for (var i = 0; i < len; i++) {
      t += newStr.slice(i, i + 1).concat(newStr.slice(i, i + 1));
    }
    newStr = t;
  }
  var arr = []; //将字符串分隔，两个两个的分隔
  for (var _i = 0; _i < 6; _i = _i + 2) {
    var s = newStr.slice(_i, _i + 2);
    arr.push(parseInt("0x" + s));
  }
  return "rgb(" + arr.join(",") + ")";
}

/**
 * 对象动态不定深度取值
 */
function getProp(obj, str) {
  str = str.replace(/\[(\w+)\]/g, ".$1"); // 处理数组下标
  var arr = str ? str.split(".") : [];
  for (var i in arr) {
    obj = obj[arr[i]] || "";
  }
  return obj;
}

// export function equals(obj1, obj2) {
//   // 首先判断是否是引用类型
//   var f1 = obj1 instanceof Object;
//   var f2 = obj2 instanceof Object;
//   // 如果有一个不是引用数据类型，那就进行直接判断。（内存地址不同）
//   if (!f1 || !f2) {
//     return obj1 === obj2;
//   }
//   // 若全是引用类型的，那就先看一下属性值的长度是否相等，若不相等，就直接false啦。
//   if (Object.keys(obj1).length !== Object.keys(obj2).length) {
//     return false;
//   }
//   /**
//    * 若属性值的长度相等，就接着遍历里边的每一个属性，还是先看里边的属性是哪一个类型，
//    * 如果全是引用类型，那就接着对里边的属性调用equals递归函数。
//    * 如果不全是引用类型，那就比较这两个值是否相等，若不相等就直接false啦。
//    */
//   for (var p in obj1) {
//     var a = obj1[p] instanceof Object;
//     var b = obj2[p] instanceof Object;
//     if (a && b) {
//       equals(obj1[p], obj2[p]);
//     } else if (obj1[p] != obj2[p]) {
//       return false;
//     }
//   }
//   return true;
// }
// 判断此类型是否是Object类型
function isObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]';
}
function equals(oldData, newData) {
  // 类型为基本类型时,如果相同,则返回true
  if (Object.is(oldData, newData)) return true;
  if (isObject(oldData) && isObject(newData) && Object.keys(oldData).length === Object.keys(newData).length) {
    // 类型为对象并且元素个数相同
    // 遍历所有对象中所有属性,判断元素是否相同
    for (var key in oldData) {
      if (oldData.hasOwnProperty(key)) {
        if (!equals(oldData[key], newData[key])) {
          // 对象中具有不相同属性 返回false
          return false;
        }
      }
    }
  } else if (Array.isArray(oldData) && Array.isArray(oldData) && oldData.length === newData.length) {
    // 类型为数组并且数组长度相同
    for (var i = 0, length = oldData.length; i < length; i++) {
      if (!equals(oldData[i], newData[i])) {
        // 如果数组元素中具有不相同元素,返回false
        return false;
      }
    }
  } else {
    // 其它类型,均返回false
    return false;
  }
  // 走到这里,说明数组或者对象中所有元素都相同,返回true
  return true;
}
function isNumberStr(str) {
  return /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g.test(str);
}
var beautifierConf = {
  html: {
    indent_size: "2",
    indent_char: " ",
    max_preserve_newlines: "-1",
    preserve_newlines: false,
    keep_array_indentation: false,
    break_chained_methods: false,
    indent_scripts: "separate",
    brace_style: "end-expand",
    space_before_conditional: true,
    unescape_strings: false,
    jslint_happy: false,
    end_with_newline: true,
    wrap_line_length: "110",
    indent_inner_html: true,
    comma_first: false,
    e4x: true,
    indent_empty_lines: true
  },
  js: {
    indent_size: "2",
    indent_char: " ",
    max_preserve_newlines: "-1",
    preserve_newlines: false,
    keep_array_indentation: false,
    break_chained_methods: false,
    indent_scripts: "normal",
    brace_style: "end-expand",
    space_before_conditional: true,
    unescape_strings: false,
    jslint_happy: true,
    end_with_newline: true,
    wrap_line_length: "110",
    indent_inner_html: true,
    comma_first: false,
    e4x: true,
    indent_empty_lines: true
  }
};
// 首字母大小
exports.beautifierConf = beautifierConf;
function titleCase(str) {
  return str.replace(/( |^)[a-z]/g, function (L) {
    return L.toUpperCase();
  });
}
var exportDefault = "export default ";

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 * @param {*} rootId 根Id 默认 0
 */
exports.exportDefault = exportDefault;
function handleTree(data, id, parentId, children, rootId) {
  id = id || "id";
  parentId = parentId || "parentId";
  children = children || "children";
  rootId = rootId || Math.min.apply(Math, data.map(function (item) {
    return item[parentId];
  })) || 0;
  //对源数据深度克隆
  var cloneData = JSON.parse(JSON.stringify(data));
  //循环所有项
  var treeData = cloneData.filter(function (father) {
    var branchArr = cloneData.filter(function (child) {
      //返回每一项的子级数组
      return father[id] === child[parentId];
    });
    branchArr.length > 0 && (father.children = branchArr);
    //返回第一层
    return father[parentId] === rootId;
  });
  return treeData !== "" ? treeData : data;
}

/**
 * 树形结构扁平化
 */
function treeFlat(data) {
  var cloneData = deepClone(data);
  return cloneData.reduce(function (res, item) {
    var children = item.children,
      i = (0, _objectWithoutProperties2.default)(item, ["children"]);
    return res.concat(i, children && children.length ? treeFlat(children) : []);
  }, []);
}

/**
 * 整理合同房源字符串
 * @param {Array} contractList 合同列表
 * @returns {Array} 整理完成的合同列表
 */
function getRoomStr(contractList) {
  var copyList = deepClone(contractList);
  copyList.forEach(function (item) {
    var _item$item;
    var buildObj = {};
    item.officeList.forEach(function (room) {
      if (buildObj["".concat(room.building, "\u5E62").concat(room.unit, "\u5355\u5143-").concat(room.floor, "F-")]) {
        buildObj["".concat(room.building, "\u5E62").concat(room.unit, "\u5355\u5143-").concat(room.floor, "F-")].push("".concat(room.roomNo));
      } else {
        buildObj["".concat(room.building, "\u5E62").concat(room.unit, "\u5355\u5143-").concat(room.floor, "F-")] = ["".concat(room.roomNo)];
      }
    });
    var roomStr = "";
    Object.keys(buildObj).forEach(function (key) {
      roomStr += "".concat(key).concat(buildObj[key].join("、"));
    });
    item.operationContractName = "".concat((_item$item = item.item) === null || _item$item === void 0 ? void 0 : _item$item.name, " / ").concat(roomStr);
  });
  return copyList;
}