"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createFieldObject = createFieldObject;
exports.createListenerObject = createListenerObject;
exports.createScriptObject = createScriptObject;
exports.updateElementExtensions = updateElementExtensions;
exports.uuid = uuid;
require("core-js/modules/es6.function.name");
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));
require("core-js/modules/es6.regexp.replace");
// 创建监听器实例
function createListenerObject(options, isTask, prefix) {
  var listenerObj = Object.create(null);
  listenerObj.event = options.event;
  isTask && (listenerObj.id = options.id); // 任务监听器特有的 id 字段
  switch (options.listenerType) {
    case "scriptListener":
      listenerObj.script = createScriptObject(options, prefix);
      break;
    case "expressionListener":
      listenerObj.expression = options.expression;
      break;
    case "delegateExpressionListener":
      listenerObj.delegateExpression = options.delegateExpression;
      break;
    default:
      listenerObj.class = options.class;
  }
  // 注入字段
  if (options.fields) {
    listenerObj.fields = options.fields.map(function (field) {
      return createFieldObject(field, prefix);
    });
  }
  // 任务监听器的 定时器 设置
  if (isTask && options.event === "timeout" && !!options.eventDefinitionType) {
    var timeDefinition = window.bpmnInstances.moddle.create("bpmn:FormalExpression", {
      body: options.eventTimeDefinitions
    });
    var TimerEventDefinition = window.bpmnInstances.moddle.create("bpmn:TimerEventDefinition", (0, _defineProperty2.default)({
      id: "TimerEventDefinition_".concat(uuid(8))
    }, "time".concat(options.eventDefinitionType.replace(/^\S/, function (s) {
      return s.toUpperCase();
    })), timeDefinition));
    listenerObj.eventDefinitions = [TimerEventDefinition];
  }
  return window.bpmnInstances.moddle.create("".concat(prefix, ":").concat(isTask ? "TaskListener" : "ExecutionListener"), listenerObj);
}

// 创建 监听器的注入字段 实例
function createFieldObject(option, prefix) {
  var name = option.name,
    fieldType = option.fieldType,
    string = option.string,
    expression = option.expression;
  var fieldConfig = fieldType === "string" ? {
    name: name,
    string: string
  } : {
    name: name,
    expression: expression
  };
  return window.bpmnInstances.moddle.create("".concat(prefix, ":Field"), fieldConfig);
}

// 创建脚本实例
function createScriptObject(options, prefix) {
  var scriptType = options.scriptType,
    scriptFormat = options.scriptFormat,
    value = options.value,
    resource = options.resource;
  var scriptConfig = scriptType === "inlineScript" ? {
    scriptFormat: scriptFormat,
    value: value
  } : {
    scriptFormat: scriptFormat,
    resource: resource
  };
  return window.bpmnInstances.moddle.create("".concat(prefix, ":Script"), scriptConfig);
}

// 更新元素扩展属性
function updateElementExtensions(element, extensionList) {
  var extensions = window.bpmnInstances.moddle.create("bpmn:ExtensionElements", {
    values: extensionList
  });
  window.bpmnInstances.modeling.updateProperties(element, {
    extensionElements: extensions
  });
}

// 创建一个id
function uuid() {
  var length = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 8;
  var chars = arguments.length > 1 ? arguments[1] : undefined;
  var result = "";
  var charsString = chars || "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  for (var i = length; i > 0; --i) {
    result += charsString[Math.floor(Math.random() * charsString.length)];
  }
  return result;
}