"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _imageViewer = _interopRequireDefault(require("element-ui/packages/image/src/image-viewer"));
var _vue = _interopRequireDefault(require("vue"));
require("./index.css");
var _default = {
  name: 'MyImgViewer',
  provide: function provide() {
    return {
      openImgViewer: this.openImgViewer
    };
  },
  data: function data() {
    return {
      _myImgViewer: null,
      // 显示图片查看器
      showImgViewer: false,
      // 查看大图列表
      imgPreviewList: []
    };
  },
  watch: {
    showImgViewer: function showImgViewer(val) {
      if (val) {
        this._myImgViewer = new (_vue.default.extend(_imageViewer.default))();
        this._myImgViewer.zIndex = 29999;
        this._myImgViewer.onClose = this.closeImgViewer;
        this._myImgViewer.urlList = this.imgPreviewList;
        this._myImgViewer.$mount();
      } else {
        this._myImgViewer.$destroy();
      }
    }
  },
  created: function created() {},
  methods: {
    // 关闭图片查看器
    closeImgViewer: function closeImgViewer() {
      this.showImgViewer = false;
      window.document.body.classList.remove('my-img-viewer-body');
    },
    // 打开图片查看器
    openImgViewer: function openImgViewer(urlArr) {
      // 获取要查看的图片临时地址
      this.imgPreviewList = urlArr;
      // 显示图片查看器
      this.showImgViewer = true;
      window.document.body.classList.add('my-img-viewer-body');
    }
  }
};
exports.default = _default;