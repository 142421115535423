"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setColor = exports.logOut = exports.default = void 0;
// import { login, getInfo, logout } from '@/api/login'
// import { getToken, setToken, removeToken } from '@/utils/auth'

var color = {
  state: {
    color: {
      prColor: '#FFFFFF'
    },
    colorGroup: {
      //弹窗颜色
      dialog_header: '#fff',
      //弹窗文字颜色
      header_text: '#000',
      //左侧导航
      //文字默认颜色
      menuText: '#1A1C1F',
      //二级菜单选中的文字颜色
      menuActiveText: '#125AFA',
      //二级菜单选中的背景颜色
      childMenuBg: '#fff',
      //父级菜单默认背景颜色
      menuBg: '#fff',
      //父级菜单hover背景颜色
      menuHover: '#D0DEFE',
      //子菜单激活后的父级文字颜色
      subMenuActiveText: '#1A1C1F',
      //子菜单默认背景颜色
      subMenuBg: '#fff',
      //子菜单hover背景颜色
      subMenuHover: '#fff',
      menuUl: '#0b1b44'
    },
    roles: [],
    // 第一次加载菜单时用到
    loadMenus: false
  },
  mutations: {
    SET_COLOR: function SET_COLOR(state, color) {
      state.color.prColor = color;
    },
    SET_COLORGROUP: function SET_COLORGROUP(state, colorGroup) {
      state.colorGroup = colorGroup;
    }
  },
  actions: {}
};
var logOut = function logOut(commit) {};
exports.logOut = logOut;
var setColor = function setColor(res, commit) {};
exports.setColor = setColor;
var _default = color;
exports.default = _default;