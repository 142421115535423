exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuText": "var(--menuText, #fff)",
	"menuActiveText": "var(--menuActiveText, #fff)",
	"subMenuActiveText": "var(--subMenuActiveText, #3485fa)",
	"menuBg": "var(--menuBg, #fff)",
	"menuHover": "var(--menuHover, #132149)",
	"subMenuBg": "var(--subMenuBg, #192a5a)",
	"subMenuHover": "var(--subMenuHover, #4c80fa)",
	"sideBarWidth": "205px",
	"dialog_header": "var(--dialog_header, #304156)",
	"header_text": "var(--header_text, #fff)",
	"childMenuBg": "var(--childMenuBg, #3b6fe6)",
	"menuUl": "var(--menuUl, #0b1b44)"
};