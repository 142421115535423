"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "ElementProperties",
  props: {
    id: String,
    type: String
  },
  inject: {
    prefix: "prefix",
    width: "width"
  },
  data: function data() {
    return {
      elementPropertyList: [],
      propertyForm: {},
      editingPropertyIndex: -1,
      propertyFormModelVisible: false
    };
  },
  watch: {
    id: {
      immediate: true,
      handler: function handler(val) {
        val && val.length && this.resetAttributesList();
      }
    }
  },
  methods: {
    resetAttributesList: function resetAttributesList() {
      var _this$bpmnElement$bus,
        _this$bpmnElement$bus2,
        _this$bpmnElement$bus3,
        _this$bpmnElement$bus4,
        _this = this,
        _this$bpmnElementProp;
      this.bpmnElement = window.bpmnInstances.bpmnElement;
      this.otherExtensionList = []; // 其他扩展配置
      this.bpmnElementProperties = (_this$bpmnElement$bus = (_this$bpmnElement$bus2 = this.bpmnElement.businessObject) === null || _this$bpmnElement$bus2 === void 0 ? void 0 : (_this$bpmnElement$bus3 = _this$bpmnElement$bus2.extensionElements) === null || _this$bpmnElement$bus3 === void 0 ? void 0 : (_this$bpmnElement$bus4 = _this$bpmnElement$bus3.values) === null || _this$bpmnElement$bus4 === void 0 ? void 0 : _this$bpmnElement$bus4.filter(function (ex) {
        if (ex.$type !== "".concat(_this.prefix, ":Properties")) {
          _this.otherExtensionList.push(ex);
        }
        return ex.$type === "".concat(_this.prefix, ":Properties");
      })) !== null && _this$bpmnElement$bus !== void 0 ? _this$bpmnElement$bus : [];

      // 保存所有的 扩展属性字段
      this.bpmnElementPropertyList = this.bpmnElementProperties.reduce(function (pre, current) {
        return pre.concat(current.values);
      }, []);
      // 复制 显示
      this.elementPropertyList = JSON.parse(JSON.stringify((_this$bpmnElementProp = this.bpmnElementPropertyList) !== null && _this$bpmnElementProp !== void 0 ? _this$bpmnElementProp : []));
    },
    openAttributesForm: function openAttributesForm(attr, index) {
      var _this2 = this;
      this.editingPropertyIndex = index;
      this.propertyForm = index === -1 ? {} : JSON.parse(JSON.stringify(attr));
      this.propertyFormModelVisible = true;
      this.$nextTick(function () {
        if (_this2.$refs["attributeFormRef"]) _this2.$refs["attributeFormRef"].clearValidate();
      });
    },
    removeAttributes: function removeAttributes(attr, index) {
      var _this3 = this;
      this.$confirm("确认移除该属性吗？", "提示", {
        confirmButtonText: "确 认",
        cancelButtonText: "取 消"
      }).then(function () {
        _this3.elementPropertyList.splice(index, 1);
        _this3.bpmnElementPropertyList.splice(index, 1);
        // 新建一个属性字段的保存列表
        var propertiesObject = window.bpmnInstances.moddle.create("".concat(_this3.prefix, ":Properties"), {
          values: _this3.bpmnElementPropertyList
        });
        _this3.updateElementExtensions(propertiesObject);
        _this3.resetAttributesList();
      }).catch(function () {
        return console.info("操作取消");
      });
    },
    saveAttribute: function saveAttribute() {
      var _this$propertyForm = this.propertyForm,
        name = _this$propertyForm.name,
        value = _this$propertyForm.value;
      if (this.editingPropertyIndex !== -1) {
        window.bpmnInstances.modeling.updateModdleProperties(this.bpmnElement, this.bpmnElementPropertyList[this.editingPropertyIndex], {
          name: name,
          value: value
        });
      } else {
        // 新建属性字段
        var newPropertyObject = window.bpmnInstances.moddle.create("".concat(this.prefix, ":Property"), {
          name: name,
          value: value
        });
        // 新建一个属性字段的保存列表
        var propertiesObject = window.bpmnInstances.moddle.create("".concat(this.prefix, ":Properties"), {
          values: this.bpmnElementPropertyList.concat([newPropertyObject])
        });
        this.updateElementExtensions(propertiesObject);
      }
      this.propertyFormModelVisible = false;
      this.resetAttributesList();
    },
    updateElementExtensions: function updateElementExtensions(properties) {
      var extensions = window.bpmnInstances.moddle.create("bpmn:ExtensionElements", {
        values: this.otherExtensionList.concat([properties])
      });
      window.bpmnInstances.modeling.updateProperties(this.bpmnElement, {
        extensionElements: extensions
      });
    }
  }
};
exports.default = _default;