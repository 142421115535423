var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-process-designer" }, [
    _c("div", { staticClass: "my-process-designer__container" }, [
      _c("div", {
        ref: "bpmn-canvas",
        staticClass: "my-process-designer__canvas"
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }