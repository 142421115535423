"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var itemInfo = {
  state: {
    // 选定的项目信息
    itemInfo: {}
  },
  mutations: {
    SET_itemInfo: function SET_itemInfo(state, itemInfo) {
      state.itemInfo = itemInfo;
    }
  },
  actions: {
    saveItemInfo: function saveItemInfo(_ref, data) {
      var commit = _ref.commit;
      commit('SET_itemInfo', data);
    }
  }
};
var _default = itemInfo;
exports.default = _default;