"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    text: {
      type: String,
      default: "暂无数据"
    },
    height: {
      type: String,
      default: "9.375rem /* 150/16 */"
    }
  }
};
exports.default = _default;