"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _ProcessViewer = _interopRequireDefault(require("./ProcessViewer.vue"));
_ProcessViewer.default.install = function (Vue) {
  Vue.component(_ProcessViewer.default.name, _ProcessViewer.default);
};

// 流程图的查看器，不可编辑
var _default = _ProcessViewer.default;
exports.default = _default;