var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fdc fcenter container" }, [
    _c("div", { style: "height:" + _vm.height }, [
      _c("img", {
        staticClass: "img",
        attrs: {
          src:
            "https://hcyx-saas.obs.cn-east-3.myhuaweicloud.com/merchant/2023/11/23/c9a349e7f820480f9f77edb8ded31117.png"
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticStyle: { color: "#909399" } }, [
      _vm._v("\n    " + _vm._s(_vm.text) + "\n  ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }