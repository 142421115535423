"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _axios = _interopRequireDefault(require("axios"));
var _routers = _interopRequireDefault(require("@/router/routers"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("../store"));
var _auth = require("@/utils/auth");
var _settings = _interopRequireDefault(require("@/settings"));
// 创建axios实例
var service = _axios.default.create();
service.defaults.baseURL = process.env.VUE_APP_BASE_API;
service.defaults.timeout = _settings.default.timeout;

/**
 *
 * @param {Array} arr1 页面传过来的权限列表
 * @param {Array} arr2 vuex中存入的用户权限总列表
 * @returns {Boolean} 页面传入的权限是否全被包含
 */
function hadPermission(arr1) {
  var arr2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  // 判断一个数组是否为另一个数组的子集
  return arr1.every(function (item) {
    return arr2.includes(item);
  });
}
var getTitle = "列表";

// request拦截器
service.interceptors.request.use(function (config) {
  getTitle = config.title ? config.title : getTitle;
  if ((0, _auth.getToken)()) {
    config.headers["Authorization"] = (0, _auth.getToken)(); // 让每个请求携带自定义token 请根据实际情况自行修改
  }

  config.headers["Content-Type"] = "application/json";
  return config;
}, function (error) {
  // Do something with request error
  console.log(error); // for debug
  Promise.reject(error);
});

// response 拦截器
service.interceptors.response.use(function (response) {
  var code = response.status;
  if (code < 200 || code > 300) {
    _elementUi.Notification.closeAll();
    _elementUi.Notification.error({
      title: "错误",
      message: response.message
    });
    return Promise.reject("error");
  } else {
    var netWorkSuccessCode = response.data.status;
    if (netWorkSuccessCode < 200 || netWorkSuccessCode > 300) {
      if (netWorkSuccessCode === "401" && response.config.url !== "/htapi/htauth/auth/logout") {
        _elementUi.MessageBox.confirm("登录状态已过期，您可以继续留在该页面，或者重新登录", "系统提示", {
          confirmButtonText: "重新登录",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          _store.default.dispatch("LogOut").then(function () {
            location.reload(); // 为了重新实例化vue-router对象 避免bug
          });
        });
      } else if (netWorkSuccessCode === "401" && response.config.url === "/htapi/htauth/auth/logout") {
        return response.data;
      } else if (netWorkSuccessCode === "403") {
        _routers.default.push({
          path: "/401"
        });
      } else {
        _elementUi.Notification.closeAll();
        _elementUi.Notification.error({
          title: "错误",
          message: response.data.message
        });
      }
      return Promise.reject("error");
    }
    return response.data.result;
  }
}, function (error) {
  var code = 0;
  try {
    code = error.response.data.status;
  } catch (e) {
    if (error.toString().indexOf("Error: timeout") !== -1) {
      _elementUi.Notification.closeAll();
      _elementUi.Notification.error({
        title: "错误",
        message: "网络请求超时",
        duration: 5000
      });
      return Promise.reject(error);
    }
  }
  if (code) {
    if (code === 401) {
      _elementUi.MessageBox.confirm("登录状态已过期，您可以继续留在该页面，或者重新登录", "系统提示", {
        confirmButtonText: "重新登录",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _store.default.dispatch("LogOut").then(function () {
          location.reload(); // 为了重新实例化vue-router对象 避免bug
        });
      });
    } else if (code === 403) {
      _routers.default.push({
        path: "/401"
      });
    } else {
      var errorMsg = error.response.data.message || error.response.data.error;
      if (errorMsg !== undefined) {
        _elementUi.Notification.closeAll();
        _elementUi.Notification.error({
          title: "错误",
          message: errorMsg,
          duration: 5000
        });
      }
    }
  } else {
    if (error.toString().indexOf("Cannot read properties of undefined (reading 'cancelToken')") !== -1) {
      _elementUi.Notification.closeAll();
      _elementUi.Notification.error({
        title: "错误",
        message: "\u6682\u65E0\u6743\u9650\u8BBF\u95EE".concat(getTitle),
        duration: 5000
      });
      return Promise.reject(error);
    }
    _elementUi.Notification.closeAll();
    _elementUi.Notification.error({
      title: "错误",
      message: "接口请求失败",
      duration: 5000
    });
  }
  return Promise.reject(error);
});
var _default = service;
exports.default = _default;