"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "UserTask",
  props: {
    id: String,
    type: String
  },
  data: function data() {
    return {
      defaultTaskForm: {
        assignee: "",
        candidateUsers: [],
        candidateGroups: [],
        dueDate: "",
        followUpDate: "",
        priority: ""
      },
      userTaskForm: {},
      mockData: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    };
  },
  watch: {
    id: {
      immediate: true,
      handler: function handler() {
        var _this = this;
        this.bpmnElement = window.bpmnInstances.bpmnElement;
        this.$nextTick(function () {
          return _this.resetTaskForm();
        });
      }
    }
  },
  methods: {
    resetTaskForm: function resetTaskForm() {
      for (var key in this.defaultTaskForm) {
        var value = void 0;
        if (key === "candidateUsers" || key === "candidateGroups") {
          var _this$bpmnElement;
          value = (_this$bpmnElement = this.bpmnElement) !== null && _this$bpmnElement !== void 0 && _this$bpmnElement.businessObject[key] ? this.bpmnElement.businessObject[key].split(",") : [];
        } else {
          var _this$bpmnElement2;
          value = ((_this$bpmnElement2 = this.bpmnElement) === null || _this$bpmnElement2 === void 0 ? void 0 : _this$bpmnElement2.businessObject[key]) || this.defaultTaskForm[key];
        }
        this.$set(this.userTaskForm, key, value);
      }
    },
    updateElementTask: function updateElementTask(key) {
      var taskAttr = Object.create(null);
      if (key === "candidateUsers" || key === "candidateGroups") {
        taskAttr[key] = this.userTaskForm[key] && this.userTaskForm[key].length ? this.userTaskForm[key].join() : null;
      } else {
        taskAttr[key] = this.userTaskForm[key] || null;
      }
      window.bpmnInstances.modeling.updateProperties(this.bpmnElement, taskAttr);
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.bpmnElement = null;
  }
};
exports.default = _default;