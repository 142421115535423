"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.updateAvatar = updateAvatar;
exports.updateBaseInfo = updateBaseInfo;
exports.updatePass = updatePass;
var _request = _interopRequireDefault(require("@/utils/request"));
var _rsaEncrypt = require("@/utils/rsaEncrypt");
function updateAvatar(data) {
  return (0, _request.default)({
    url: "htapi/htauth/api/uaCenter/users/updateAvatarV2",
    method: "post",
    data: data
  });
}
function updatePass(user) {
  var data = {
    oldPass: (0, _rsaEncrypt.encrypt)(user.oldPass),
    newPass: (0, _rsaEncrypt.encrypt)(user.newPass)
  };
  return (0, _request.default)({
    url: "htapi/htauth/api/uaCenter/users/updatePass",
    method: "post",
    data: data
  });
}

// 登录用户修改个人资料
function updateBaseInfo(data) {
  return (0, _request.default)({
    url: "htapi/htauth/api/uaCenter/users/modifyInfo",
    method: "post",
    data: data
  });
}
var _default = {
  updateAvatar: updateAvatar,
  updateBaseInfo: updateBaseInfo,
  updatePass: updatePass
};
exports.default = _default;