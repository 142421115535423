"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
// 定义全局自定义指令

var copy = function copy(Vue) {
  // el-select组件数据过多，使用翻页加载数据指令
  Vue.directive('copy', {
    bind: function bind(el, _ref) {
      var value = _ref.value;
      el.$value = value;
      el.handler = function () {
        if (!el.$value) {
          // 值为空的时候，给出提示。可根据项目UI仔细设计
          console.log('无复制内容');
          return;
        }
        // 动态创建 textarea 标签
        var textarea = document.createElement('textarea');
        // 将该 textarea 设为 readonly 防止 iOS 下自动唤起键盘，同时将 textarea 移出可视区域
        textarea.readOnly = 'readonly';
        textarea.style.position = 'absolute';
        textarea.style.left = '-9999px';
        // 将要 copy 的值赋给 textarea 标签的 value 属性
        textarea.value = el.$value;
        // 将 textarea 插入到 body 中
        document.body.appendChild(textarea);
        // 选中值并复制
        textarea.select();
        var result = document.execCommand('Copy');
        if (result) {
          console.log('复制成功'); // 可根据项目UI仔细设计
        }

        document.body.removeChild(textarea);
      };
      // 绑定点击事件，就是所谓的一键 copy 啦
      el.addEventListener('click', el.handler);
    },
    // 当传进来的值更新的时候触发
    componentUpdated: function componentUpdated(el, _ref2) {
      var value = _ref2.value;
      el.$value = value;
    },
    // 指令与元素解绑的时候，移除事件绑定
    unbind: function unbind(el) {
      el.removeEventListener('click', el.handler);
    }
  });
};
if (window.Vue) {
  _vue.default.use(copy);
}
var _default = copy;
exports.default = _default;