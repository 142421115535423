"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadMenus = void 0;
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));
require("core-js/modules/es6.array.find");
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));
require("core-js/modules/es7.object.entries");
require("core-js/modules/web.dom.iterable");
var _routers = _interopRequireDefault(require("./routers"));
var _store = _interopRequireDefault(require("@/store"));
var _settings = _interopRequireDefault(require("@/settings"));
var _nprogress = _interopRequireDefault(require("nprogress"));
require("nprogress/nprogress.css");
var _auth = require("@/utils/auth");
var _menu = require("@/api/system/menu");
var _permission = require("@/store/modules/permission");
var _elementUi = require("element-ui");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; } /* eslint-disable */ // progress bar
// progress bar style
// getToken from cookie
_nprogress.default.configure({
  showSpinner: false
}); // NProgress Configuration

var whiteList = ["/login"]; // no redirect whitelist

function getcolor() {
  var colorObj = _store.default.state.color.colorGroup;
  Object.entries(colorObj).forEach(function (ele) {
    // 逐个设置 css 变量到 body 上
    document.body.style.setProperty("--".concat(ele[0]), ele[1]);
  });
}
_routers.default.beforeEach(function (to, from, next) {
  if (to.meta.title) {
    document.title = to.meta.title + " - " + _settings.default.title;
  }
  _nprogress.default.start();
  if ((0, _auth.getToken)()) {
    // 已登录且要跳转的页面是登录页
    if (to.path === "/login") {
      next();
      _nprogress.default.done();
    } else if (to.path === "/statistics") {
      window.open("/chart", "_blank");
      _nprogress.default.done();
    } else {
      if (_store.default.getters.roles.length === 0) {
        // 拉取user_info
        // 动态路由，拉取菜单
        // 判断当前用户是否已拉取完user_info信息
        _store.default.dispatch("GetInfo").then(function (res) {
          // 判断是否更改过密码 0-没有修改，1-已经修改
          if (res.user.initPasswordModifyFlag) {
            loadMenus(next, to);
          } else {
            _elementUi.MessageBox.confirm("检测到您从未更改过密码，为了您的账号安全，建议您尽快修改密码", "系统提示", {
              confirmButtonText: "立即修改",
              type: "warning",
              showClose: false,
              closeOnPressEscape: false,
              showCancelButton: false,
              closeOnClickModal: false
            }).then(function () {
              _routers.default.addRoutes([{
                path: "*",
                redirect: "/404",
                hidden: true
              }]);
              next({
                path: "/user/center",
                query: {
                  editPassword: true
                }
              });
            });
          }
        }).catch(function (err) {
          console.log(err);
          _store.default.dispatch("LogOut").then(function () {
            location.reload(); // 为了重新实例化vue-router对象 避免bug
          });
        });
        // 登录时未拉取 菜单，在此处拉取
      } else if (_store.default.getters.loadMenus) {
        // 判断是否更改过密码 0-没有修改，1-已经修改
        if (_store.default.getters.user.initPasswordModifyFlag) {
          // 修改成false，防止死循环
          _store.default.dispatch("updateLoadMenus");
          loadMenus(next, to);
        } else {
          _elementUi.MessageBox.confirm("检测到您从未更改过密码，为了您的账号安全，建议您尽快修改密码", "系统提示", {
            confirmButtonText: "立即修改",
            type: "warning",
            showClose: false,
            showCancelButton: false,
            closeOnPressEscape: false,
            closeOnClickModal: false
          }).then(function () {
            _store.default.dispatch("updateLoadMenus");
            _routers.default.addRoutes([{
              path: "*",
              redirect: "/404",
              hidden: true
            }]);
            next({
              path: "/user/center",
              query: {
                editPassword: true
              }
            });
          });
        }
      } else {
        getcolor();
        next();
      }
    }
  } else {
    // /!* has no token*!/
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next("/login?redirect=".concat(to.path)); // 否则全部重定向到登录页
      _nprogress.default.done();
    }
  }
});
var loadMenus = function loadMenus(next, to) {
  // 获取系统管理-菜单管理下配置的所有目录/菜单
  (0, _menu.buildMenus)().then(function (res) {
    res.forEach(function (item) {
      item.path = item.path[0] === "/" ? item.path : "/".concat(item.path);
    });
    // 处理配置菜单，带上全局Layout,并塞进一个404重定向
    var asyncRouter = (0, _permission.filterAsyncRouter)(res);
    var firstRoute = asyncRouter[0];
    asyncRouter.push.apply(asyncRouter, (0, _toConsumableArray2.default)(asyncRouter.find(function (item) {
      return item.path === "/";
    }) ? [] : [{
      path: "/",
      redirect: findChild(firstRoute.path, firstRoute)
    }]).concat([{
      path: "*",
      redirect: "/404",
      hidden: true
    }]));
    _store.default.dispatch("GenerateRoutes", asyncRouter).then(function () {
      // 添加路由缓存，具体缓存干嘛用还没看
      asyncRouter.forEach(function (father) {
        if (father.children) {
          father.children.forEach(function (child) {
            _store.default.dispatch("tagsView/addCachedView", child);
          });
        }
      });
      // 存储路由
      _routers.default.addRoutes(asyncRouter); // 动态添加可访问路由表
      next(_objectSpread(_objectSpread({}, to), {}, {
        replace: true
      }));
    });
  });
};
exports.loadMenus = loadMenus;
var findChild = function findChild(path, obj) {
  if (obj.children) {
    return findChild(path + "/" + obj.children[0].path, obj.children[0]);
  } else {
    return path;
  }
};
_routers.default.afterEach(function () {
  _nprogress.default.done(); // finish progress bar
});