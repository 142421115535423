"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRouterMap = void 0;
var _typeof2 = _interopRequireDefault(require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime-corejs2/helpers/typeof"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _index = _interopRequireDefault(require("../layout/index"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || (0, _typeof2.default)(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
_vue.default.use(_vueRouter.default);
var constantRouterMap = [{
  path: '/login',
  meta: {
    title: '登录',
    noCache: true
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/features/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/features/401'));
    });
  },
  hidden: true
}, {
  path: '/406',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/features/401'));
    });
  },
  hidden: true
}, {
  path: '/redirect',
  component: _index.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/features/redirect'));
      });
    }
  }]
},
// {
//   path: '/',
//   component: Layout,
//   redirect: '/dashboard',
//   children: [
//     {
//       path: 'dashboard',
//       component: () => import(/* webpackChunkName: "routerGroup" */ '@/views/home'),
//       name: 'Dashboard',
//       meta: { title: '工作台', icon: 'index', affix: true, noCache: true, icon: 'dashboard' }
//     }

//   ]
// },
{
  path: '/user',
  component: _index.default,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'center',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/system/user/center.vue'));
      });
    },
    name: '个人中心',
    meta: {
      title: '个人中心'
    }
  }]
}];
exports.constantRouterMap = constantRouterMap;
var _default = new _vueRouter.default({
  mode: 'history',
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRouterMap
});
exports.default = _default;