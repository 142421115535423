"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));
var _index = _interopRequireDefault(require("@/mixins/MyImgViewer/index"));
var _index2 = _interopRequireDefault(require("@/layout/index.vue"));
var _vuex = require("vuex");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; } //
//
//
//
//
//
var _default = {
  mixins: [_index.default],
  name: "App",
  components: {
    Layout: _index2.default
  },
  data: function data() {
    return {
      refresh: true,
      keepAliveCompList: []
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(["permission_routers"])),
  mounted: function mounted() {
    var _this = this;
    this.$EventBus.$on("refresh", function (data) {
      _this.refresh = false;
      _this.$nextTick(function () {
        _this.refresh = true;
        _this.$router.go(0);
      });
    });
  },
  methods: {}
};
exports.default = _default;