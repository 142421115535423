"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.socialCreditCode18 = exports.num3No0 = exports.num3Nnoe0 = exports.num3And3 = exports.num3And0 = exports.front3 = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.math.trunc");
require("core-js/modules/es6.number.constructor");
var _vue = _interopRequireDefault(require("vue"));
// 格式化数字三位加一逗号 (为0显示)

var Big = require("big.js");
var num3And0 = _vue.default.filter("numberPutCommaShow", function (value) {
  var installVal = value;
  if (Number(value)) {
    value = Big(value).toFixed(2);
    var intPart = Math.trunc(value); // 获取整数部分
    var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); // 将整数部分逢三一断
    var floatPart = ".00"; // 预定义小数部分
    var value2Array = value.split(".");
    // =2表示数据有小数位
    if (value2Array.length === 2) {
      floatPart = value2Array[1].toString(); // 拿到小数部分
      if (floatPart.length === 1) {
        // 补0,实际上用不着
        return intPartFormat + "." + floatPart + "0";
      } else {
        if (installVal < 0 && intPartFormat == 0) {
          return "-" + intPartFormat + "." + floatPart;
        } else {
          return intPartFormat + "." + floatPart;
        }
      }
    } else {
      return intPartFormat + floatPart;
    }
  } else if (value == 0 && String(value) || value === null) {
    return "0.00";
  }
});
// 格式化数字三位加一逗号 (为0不显示)
exports.num3And0 = num3And0;
var num3No0 = _vue.default.filter("numberPutComma", function (value) {
  var installVal = value;
  if (Number(value)) {
    // if(!value) return '0.00'
    value = Big(value).toFixed(2);
    var intPart = Math.trunc(value); // 获取整数部分
    var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); // 将整数部分逢三一断
    var floatPart = ".00"; // 预定义小数部分
    var value2Array = value.split(".");
    // =2表示数据有小数位
    if (value2Array.length === 2) {
      floatPart = value2Array[1].toString(); // 拿到小数部分
      if (floatPart.length === 1) {
        // 补0,实际上用不着
        return intPartFormat + "." + floatPart + "0";
      } else {
        if (installVal < 0 && intPartFormat == 0) {
          return "-" + intPartFormat + "." + floatPart;
        } else {
          return intPartFormat + "." + floatPart;
        }
      }
    } else {
      return intPartFormat + floatPart;
    }
  }
});
// 格式化数字三位加一逗号 (为0不显示)
exports.num3No0 = num3No0;
var num3Nnoe0 = _vue.default.filter("numberPutComma", function (value) {
  // let installVal = value
  if (Number(value)) {
    // if(!value) return '0.00'
    value = Big(value).toFixed(2);
    var intPart = Math.trunc(value); // 获取整数部分
    var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); // 将整数部分逢三一断
    // let floatPart = ".00" // 预定义小数部分
    // let value2Array = value.split(".");
    // =2表示数据有小数位
    return intPartFormat;
  }
});
// 格式化数字三位加一逗号 (为0显示)保留3位小数
exports.num3Nnoe0 = num3Nnoe0;
var num3And3 = _vue.default.filter("numberPutCommaShow", function (value) {
  var installVal = value;
  if (value != "") {
    value = Big(value).toFixed(3);
    var intPart = Math.trunc(value); // 获取整数部分
    var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); // 将整数部分逢三一断
    var floatPart = ".000"; // 预定义小数部分
    var value2Array = value.split(".");

    // =2表示数据有小数位
    if (value2Array.length === 2) {
      floatPart = value2Array[1].toString(); // 拿到小数部分
      if (floatPart.length === 1) {
        // 补0,实际上用不着
        return intPartFormat + "." + floatPart + "00";
      } else if (floatPart.length === 2) {
        return intPartFormat + "." + floatPart + "0";
      } else {
        if (installVal < 0 && intPartFormat == 0) {
          return "-" + intPartFormat + "." + floatPart;
        } else {
          return intPartFormat + "." + floatPart;
        }
      }
    } else {
      return intPartFormat + floatPart;
    }
  } else if (value == 0 && String(value) || value === null) {
    return "0.000";
  }
});
//前三名
exports.num3And3 = num3And3;
var front3 = _vue.default.filter("change", function (value) {
  var installVal = value;
  if (installVal == 0) {
    return "冠军";
  } else if (installVal == 1) {
    return "亚军";
  } else if (installVal == 2) {
    return "季军";
  } else if (installVal == 3) {
    return "第四名";
  } else if (installVal == 4) {
    return "第五名";
  } else {
    return "五名以后";
  }
});
exports.front3 = front3;
var socialCreditCode18 = _vue.default.filter('trimToLast18', function (value) {
  if (typeof value !== 'string') {
    return value; // 如果不是字符串，直接返回原值
  }

  // 判断字符串是否超过18位
  if (value.length > 18) {
    return value.slice(-18); // 超过18位则从末尾向前截取18位
  } else {
    return value; // 不超过18位则返回原值
  }
});
exports.socialCreditCode18 = socialCreditCode18;