"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.object.entries");
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));
var _RightPanel = _interopRequireDefault(require("@/components/RightPanel"));
var _components = require("./components");
var _ResizeHandler = _interopRequireDefault(require("./mixin/ResizeHandler"));
var _vuex = require("vuex");
var _ThemePicker = _interopRequireDefault(require("@/components/ThemePicker"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _Breadcrumb = _interopRequireDefault(require("@/components/Breadcrumb"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Layout",
  components: {
    AppMain: _components.AppMain,
    Navbar: _components.Navbar,
    RightPanel: _RightPanel.default,
    Settings: _components.Settings,
    Sidebar: _components.Sidebar,
    TagsView: _components.TagsView,
    Theme: _ThemePicker.default,
    Breadcrumb: _Breadcrumb.default
  },
  mixins: [_ResizeHandler.default],
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapState)({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    },
    showSettings: function showSettings(state) {
      return state.settings.showSettings;
    },
    needTagsView: function needTagsView(state) {
      return state.settings.tagsView;
    },
    fixedHeader: function fixedHeader(state) {
      return state.settings.fixedHeader;
    }
  })), {}, {
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile"
      };
    }
  }),
  mounted: function mounted() {
    // this.getcolor()
    if (_jsCookie.default.get("theme")) {
      this.$refs.theme.theme = _jsCookie.default.get("theme");
      this.$store.dispatch("settings/changeSetting", {
        key: "theme",
        value: _jsCookie.default.get("theme")
      });
    }
  },
  data: function data() {
    return {
      colvalue: "rgba(255, 69, 0, 0.68)"
    };
  },
  watch: {
    colvalue: function colvalue(val) {
      // this.$store.state.color.color.prColor=val

      var colorGroup = {
        //弹窗颜色
        dialog_header: "#0E7490",
        //弹窗文字颜色
        header_text: "#ffffff",
        //左侧导航
        //文字默认颜色
        menuText: "#bfcbd9",
        //二级菜单选中的文字颜色
        menuActiveText: "#FFFFFF",
        //二级菜单选中的背景颜色
        childMenuBg: "#13b4c0",
        //父级菜单默认背景颜色
        menuBg: "#0E7490",
        //父级菜单hover背景颜色
        menuHover: "#155E75",
        //子菜单激活后的父级文字颜色
        subMenuActiveText: "#1af3ab",
        //子菜单默认背景颜色
        subMenuBg: "#0d637a",
        //子菜单hover背景颜色
        subMenuHover: "#13b4c0"
      };
      this.$store.commit("SET_COLORGROUP", colorGroup);
      var colorObj = this.$store.state.color.colorGroup;
      Object.entries(colorObj).forEach(function (ele) {
        // 逐个设置 css 变量到 body 上
        document.body.style.setProperty("--".concat(ele[0]), ele[1]);
      });
    }
  },
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", {
        withoutAnimation: false
      });
    },
    getcolor: function getcolor(e) {
      var colorGroup;
      e == 1 && (colorGroup = {
        //弹窗颜色
        dialog_header: "#0E7490",
        //弹窗文字颜色
        header_text: "#ffffff",
        //左侧导航
        //文字默认颜色
        menuText: "#ffffff",
        //二级菜单选中的文字颜色
        menuActiveText: "#FFFFFF",
        //二级菜单选中的背景颜色
        childMenuBg: "#13b4c0",
        //父级菜单默认背景颜色
        menuBg: "#0E7490",
        //父级菜单hover背景颜色
        menuHover: "#155E75",
        //子菜单激活后的父级文字颜色
        subMenuActiveText: "#1af3ab",
        //子菜单默认背景颜色
        subMenuBg: "#0d637a",
        //子菜单hover背景颜色
        subMenuHover: "#13b4c0",
        menuUl: "#0B80AE"
      });
      e == 2 && (colorGroup = {
        //弹窗颜色
        dialog_header: "#1F2E54",
        //弹窗文字颜色
        header_text: "#ffffff",
        //左侧导航
        //文字默认颜色
        menuText: "#bfcbd9",
        //二级菜单选中的文字颜色
        menuActiveText: "",
        //二级菜单选中的背景颜色
        childMenuBg: "#3B6FE6",
        //父级菜单默认背景颜色
        menuBg: "#1F2E54 ",
        //父级菜单hover背景颜色
        menuHover: "#132149",
        //子菜单激活后的父级文字颜色
        subMenuActiveText: "#3485FA",
        //子菜单默认背景颜色
        subMenuBg: "#132149",
        //子菜单hover背景颜色
        subMenuHover: "#4c80fa ",
        menuUl: "#0b1b44"
      });
      e == 3 && (colorGroup = {
        //弹窗颜色
        dialog_header: "#2A2D2E",
        //弹窗文字颜色
        header_text: "#FEC001",
        //左侧导航
        //文字默认颜色
        menuText: "#fff",
        //二级菜单选中的文字颜色
        menuActiveText: "#ffffff",
        //二级菜单选中的背景颜色
        childMenuBg: "#FEC001",
        //父级菜单默认背景颜色
        menuBg: "#2A2D2E",
        //父级菜单hover背景颜色
        menuHover: "#070D06",
        //子菜单激活后的父级文字颜色
        subMenuActiveText: "#FEC001",
        //子菜单默认背景颜色
        subMenuBg: "#2A2D2E",
        //子菜单hover背景颜色
        subMenuHover: "#FEC001",
        menuUl: "#070D06"
      });
      this.$store.commit("SET_COLORGROUP", colorGroup);
      var colorObj = this.$store.state.color.colorGroup;
      Object.entries(colorObj).forEach(function (ele) {
        // 逐个设置 css 变量到 body 上
        document.body.style.setProperty("--".concat(ele[0]), ele[1]);
      });
    }
  }
};
exports.default = _default;