var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel-tab__content" },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.elementPropertyList,
            size: "small",
            "max-height": "240",
            border: "",
            fit: ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "序号", width: "50px", type: "index" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "属性名",
              prop: "name",
              "min-width": "100px",
              "show-overflow-tooltip": ""
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "属性值",
              prop: "value",
              "min-width": "100px",
              "show-overflow-tooltip": ""
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "90px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.openAttributesForm(row, $index)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { color: "#ff4d4f" },
                        attrs: { size: "small", type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.removeAttributes(row, $index)
                          }
                        }
                      },
                      [_vm._v("移除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "element-drawer__button" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary", icon: "el-icon-plus" },
              on: {
                click: function($event) {
                  return _vm.openAttributesForm(null, -1)
                }
              }
            },
            [_vm._v("添加属性")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.propertyFormModelVisible,
            title: "属性配置",
            width: "600px",
            "append-to-body": "",
            "destroy-on-close": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.propertyFormModelVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "attributeFormRef",
              attrs: {
                model: _vm.propertyForm,
                "label-width": "80px",
                size: "small"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "属性名：", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.propertyForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.propertyForm, "name", $$v)
                      },
                      expression: "propertyForm.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "属性值：", prop: "value" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.propertyForm.value,
                      callback: function($$v) {
                        _vm.$set(_vm.propertyForm, "value", $$v)
                      },
                      expression: "propertyForm.value"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      _vm.propertyFormModelVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.saveAttribute }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }