"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "ReceiveTask",
  props: {
    id: String,
    type: String
  },
  data: function data() {
    return {
      bindMessageId: "",
      newMessageForm: {},
      messageMap: {},
      messageModelVisible: false
    };
  },
  watch: {
    id: {
      immediate: true,
      handler: function handler() {
        var _this = this;
        this.$nextTick(function () {
          return _this.getBindMessage();
        });
      }
    }
  },
  created: function created() {
    var _this2 = this;
    this.bpmnMessageRefsMap = Object.create(null);
    this.bpmnRootElements = window.bpmnInstances.modeler.getDefinitions().rootElements;
    this.bpmnRootElements.filter(function (el) {
      return el.$type === "bpmn:Message";
    }).forEach(function (m) {
      _this2.bpmnMessageRefsMap[m.id] = m;
      _this2.$set(_this2.messageMap, m.id, m.name);
    });
    this.$set(this.messageMap, "-1", "无"); // 添加一个空对象，保证可以取消原消息绑定
  },

  methods: {
    getBindMessage: function getBindMessage() {
      var _this$bpmnElement$bus, _this$bpmnElement$bus2;
      this.bpmnElement = window.bpmnInstances.bpmnElement;
      this.bindMessageId = ((_this$bpmnElement$bus = this.bpmnElement.businessObject) === null || _this$bpmnElement$bus === void 0 ? void 0 : (_this$bpmnElement$bus2 = _this$bpmnElement$bus.messageRef) === null || _this$bpmnElement$bus2 === void 0 ? void 0 : _this$bpmnElement$bus2.id) || "-1";
    },
    openMessageModel: function openMessageModel() {
      this.messageModelVisible = true;
      this.newMessageForm = {};
    },
    createNewMessage: function createNewMessage() {
      if (this.messageMap[this.newMessageForm.id]) {
        this.$message.error("该消息已存在，请修改id后重新保存");
        return;
      }
      var newMessage = window.bpmnInstances.moddle.create("bpmn:Message", this.newMessageForm);
      this.bpmnRootElements.push(newMessage);
      this.$set(this.messageMap, this.newMessageForm.id, this.newMessageForm.name);
      this.bpmnMessageRefsMap[this.newMessageForm.id] = newMessage;
      this.messageModelVisible = false;
    },
    updateTaskMessage: function updateTaskMessage(messageId) {
      if (messageId === "-1") {
        window.bpmnInstances.modeling.updateProperties(this.bpmnElement, {
          messageRef: null
        });
      } else {
        window.bpmnInstances.modeling.updateProperties(this.bpmnElement, {
          messageRef: this.bpmnMessageRefsMap[messageId]
        });
      }
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.bpmnElement = null;
  }
};
exports.default = _default;