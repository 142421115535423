"use strict";

var _interopRequireDefault = require("/root/workspace/ningbo-zixi-iot_1/qm_web_new/node_modules/@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCodeImg = getCodeImg;
exports.getInfo = getInfo;
exports.login = login;
exports.logout = logout;
var _request = _interopRequireDefault(require("@/utils/request"));
// export function login(username, password, code, uuid) {
function login(data) {
  return (0, _request.default)({
    url: 'htapi/htauth/auth/login',
    method: 'post',
    data: data
    // data: {
    //   username,
    //   password,
    //   code,
    //   uuid
    // }
  });
}

function getInfo() {
  return (0, _request.default)({
    url: 'htapi/htauth/auth/info',
    method: 'get'
  });
}
function getCodeImg() {
  return (0, _request.default)({
    url: 'htapi/htauth/auth/code',
    method: 'get'
  });
}
function logout() {
  return (0, _request.default)({
    url: 'htapi/htauth/auth/logout',
    method: 'get'
  });
}