"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "ElementForm",
  props: {
    id: String,
    type: String
  },
  inject: {
    prefix: "prefix",
    width: "width"
  },
  data: function data() {
    return {
      formKey: "",
      businessKey: "",
      optionModelTitle: "",
      fieldList: [],
      formFieldForm: {},
      fieldType: {
        long: "长整型",
        string: "字符串",
        boolean: "布尔类",
        date: "日期类",
        enum: "枚举类",
        custom: "自定义类型"
      },
      formFieldIndex: -1,
      // 编辑中的字段， -1 为新增
      formFieldOptionIndex: -1,
      // 编辑中的字段配置项， -1 为新增
      fieldModelVisible: false,
      fieldOptionModelVisible: false,
      fieldOptionForm: {},
      // 当前激活的字段配置项数据
      fieldOptionType: "",
      // 当前激活的字段配置项弹窗 类型
      fieldEnumList: [],
      // 枚举值列表
      fieldConstraintsList: [],
      // 约束条件列表
      fieldPropertiesList: [] // 绑定属性列表
    };
  },

  watch: {
    id: {
      immediate: true,
      handler: function handler(val) {
        var _this = this;
        val && val.length && this.$nextTick(function () {
          return _this.resetFormList();
        });
      }
    }
  },
  methods: {
    resetFormList: function resetFormList() {
      var _this$elExtensionElem,
        _this2 = this;
      this.bpmnELement = window.bpmnInstances.bpmnElement;
      this.formKey = this.bpmnELement.businessObject.formKey;
      // 获取元素扩展属性 或者 创建扩展属性
      this.elExtensionElements = this.bpmnELement.businessObject.get("extensionElements") || window.bpmnInstances.moddle.create("bpmn:ExtensionElements", {
        values: []
      });
      // 获取元素表单配置 或者 创建新的表单配置
      this.formData = ((_this$elExtensionElem = this.elExtensionElements.values.filter(function (ex) {
        return ex.$type === "".concat(_this2.prefix, ":FormData");
      })) === null || _this$elExtensionElem === void 0 ? void 0 : _this$elExtensionElem[0]) || window.bpmnInstances.moddle.create("".concat(this.prefix, ":FormData"), {
        fields: []
      });

      // 业务标识 businessKey， 绑定在 formData 中
      this.businessKey = this.formData.businessKey;

      // 保留剩余扩展元素，便于后面更新该元素对应属性
      this.otherExtensions = this.elExtensionElements.values.filter(function (ex) {
        return ex.$type !== "".concat(_this2.prefix, ":FormData");
      });

      // 复制原始值，填充表格
      this.fieldList = JSON.parse(JSON.stringify(this.formData.fields || []));

      // 更新元素扩展属性，避免后续报错
      this.updateElementExtensions();
    },
    updateElementFormKey: function updateElementFormKey() {
      window.bpmnInstances.modeling.updateProperties(this.bpmnELement, {
        formKey: this.formKey
      });
    },
    updateElementBusinessKey: function updateElementBusinessKey() {
      window.bpmnInstances.modeling.updateModdleProperties(this.bpmnELement, this.formData, {
        businessKey: this.businessKey
      });
    },
    // 根据类型调整字段type
    changeFieldTypeType: function changeFieldTypeType(type) {
      this.$set(this.formFieldForm, "type", type === "custom" ? "" : type);
    },
    // 打开字段详情侧边栏
    openFieldForm: function openFieldForm(field, index) {
      this.formFieldIndex = index;
      if (index !== -1) {
        var _FieldObject$validati, _FieldObject$properti;
        var FieldObject = this.formData.fields[index];
        this.formFieldForm = JSON.parse(JSON.stringify(field));
        // 设置自定义类型
        this.$set(this.formFieldForm, "typeType", !this.fieldType[field.type] ? "custom" : field.type);
        // 初始化枚举值列表
        field.type === "enum" && (this.fieldEnumList = JSON.parse(JSON.stringify((FieldObject === null || FieldObject === void 0 ? void 0 : FieldObject.values) || [])));
        // 初始化约束条件列表
        this.fieldConstraintsList = JSON.parse(JSON.stringify((FieldObject === null || FieldObject === void 0 ? void 0 : (_FieldObject$validati = FieldObject.validation) === null || _FieldObject$validati === void 0 ? void 0 : _FieldObject$validati.constraints) || []));
        // 初始化自定义属性列表
        this.fieldPropertiesList = JSON.parse(JSON.stringify((FieldObject === null || FieldObject === void 0 ? void 0 : (_FieldObject$properti = FieldObject.properties) === null || _FieldObject$properti === void 0 ? void 0 : _FieldObject$properti.values) || []));
      } else {
        this.formFieldForm = {};
        // 初始化枚举值列表
        this.fieldEnumList = [];
        // 初始化约束条件列表
        this.fieldConstraintsList = [];
        // 初始化自定义属性列表
        this.fieldPropertiesList = [];
      }
      this.fieldModelVisible = true;
    },
    // 打开字段 某个 配置项 弹窗
    openFieldOptionForm: function openFieldOptionForm(option, index, type) {
      this.fieldOptionModelVisible = true;
      this.fieldOptionType = type;
      this.formFieldOptionIndex = index;
      if (type === "property") {
        this.fieldOptionForm = option ? JSON.parse(JSON.stringify(option)) : {};
        return this.optionModelTitle = "属性配置";
      }
      if (type === "enum") {
        this.fieldOptionForm = option ? JSON.parse(JSON.stringify(option)) : {};
        return this.optionModelTitle = "枚举值配置";
      }
      this.fieldOptionForm = option ? JSON.parse(JSON.stringify(option)) : {};
      return this.optionModelTitle = "约束条件配置";
    },
    // 保存字段 某个 配置项
    saveFieldOption: function saveFieldOption() {
      if (this.formFieldOptionIndex === -1) {
        if (this.fieldOptionType === "property") {
          this.fieldPropertiesList.push(this.fieldOptionForm);
        }
        if (this.fieldOptionType === "constraint") {
          this.fieldConstraintsList.push(this.fieldOptionForm);
        }
        if (this.fieldOptionType === "enum") {
          this.fieldEnumList.push(this.fieldOptionForm);
        }
      } else {
        this.fieldOptionType === "property" && this.fieldPropertiesList.splice(this.formFieldOptionIndex, 1, this.fieldOptionForm);
        this.fieldOptionType === "constraint" && this.fieldConstraintsList.splice(this.formFieldOptionIndex, 1, this.fieldOptionForm);
        this.fieldOptionType === "enum" && this.fieldEnumList.splice(this.formFieldOptionIndex, 1, this.fieldOptionForm);
      }
      this.fieldOptionModelVisible = false;
      this.fieldOptionForm = {};
    },
    // 保存字段配置
    saveField: function saveField() {
      var _this3 = this;
      var _this$formFieldForm = this.formFieldForm,
        id = _this$formFieldForm.id,
        type = _this$formFieldForm.type,
        label = _this$formFieldForm.label,
        defaultValue = _this$formFieldForm.defaultValue,
        datePattern = _this$formFieldForm.datePattern;
      var Field = window.bpmnInstances.moddle.create("".concat(this.prefix, ":FormField"), {
        id: id,
        type: type,
        label: label
      });
      defaultValue && (Field.defaultValue = defaultValue);
      datePattern && (Field.datePattern = datePattern);
      // 构建属性
      if (this.fieldPropertiesList && this.fieldPropertiesList.length) {
        var fieldPropertyList = this.fieldPropertiesList.map(function (fp) {
          return window.bpmnInstances.moddle.create("".concat(_this3.prefix, ":Property"), {
            id: fp.id,
            value: fp.value
          });
        });
        Field.properties = window.bpmnInstances.moddle.create("".concat(this.prefix, ":Properties"), {
          values: fieldPropertyList
        });
      }
      // 构建校验规则
      if (this.fieldConstraintsList && this.fieldConstraintsList.length) {
        var fieldConstraintList = this.fieldConstraintsList.map(function (fc) {
          return window.bpmnInstances.moddle.create("".concat(_this3.prefix, ":Constraint"), {
            name: fc.name,
            config: fc.config
          });
        });
        Field.validation = window.bpmnInstances.moddle.create("".concat(this.prefix, ":Validation"), {
          constraints: fieldConstraintList
        });
      }
      // 构建枚举值
      if (this.fieldEnumList && this.fieldEnumList.length) {
        Field.values = this.fieldEnumList.map(function (fe) {
          return window.bpmnInstances.moddle.create("".concat(_this3.prefix, ":Value"), {
            name: fe.name,
            id: fe.id
          });
        });
      }
      // 更新数组 与 表单配置实例
      if (this.formFieldIndex === -1) {
        this.fieldList.push(this.formFieldForm);
        this.formData.fields.push(Field);
      } else {
        this.fieldList.splice(this.formFieldIndex, 1, this.formFieldForm);
        this.formData.fields.splice(this.formFieldIndex, 1, Field);
      }
      this.updateElementExtensions();
      this.fieldModelVisible = false;
    },
    // 移除某个 字段的 配置项
    removeFieldOptionItem: function removeFieldOptionItem(option, index, type) {
      if (type === "property") {
        this.fieldPropertiesList.splice(index, 1);
        return;
      }
      if (type === "enum") {
        this.fieldEnumList.splice(index, 1);
        return;
      }
      this.fieldConstraintsList.splice(index, 1);
    },
    // 移除 字段
    removeField: function removeField(field, index) {
      this.fieldList.splice(index, 1);
      this.formData.fields.splice(index, 1);
      this.updateElementExtensions();
    },
    updateElementExtensions: function updateElementExtensions() {
      // 更新回扩展元素
      var newElExtensionElements = window.bpmnInstances.moddle.create("bpmn:ExtensionElements", {
        values: this.otherExtensions.concat(this.formData)
      });
      // 更新到元素上
      window.bpmnInstances.modeling.updateProperties(this.bpmnELement, {
        extensionElements: newElExtensionElements
      });
    }
  }
};
exports.default = _default;