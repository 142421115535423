"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _elementUi = require("element-ui");
var DialogPathed = {
  extends: _elementUi.Dialog,
  data: function data() {
    return {
      wrapperMouseDowned: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$el.onmousedown = function (e) {
      _this.wrapperMouseDowned = e.target.classList.contains('el-dialog__wrapper');
    };
  },
  methods: {
    handleWrapperClick: function handleWrapperClick() {
      if (!this.closeOnClickModal || !this.wrapperMouseDowned) return;
      this.handleClose();
    }
  }
};
var DrawerPathed = {
  extends: _elementUi.Drawer,
  data: function data() {
    return {
      wrapperMouseDowned: false
    };
  },
  mounted: function mounted() {
    var _this2 = this;
    this.$el.onmousedown = function (e) {
      _this2.wrapperMouseDowned = e.target.classList.contains('el-drawer__container');
    };
  },
  methods: {
    handleWrapperClick: function handleWrapperClick() {
      if (this.wrapperClosable && this.wrapperMouseDowned) {
        this.closeDrawer();
      }
    }
  }
};
var _default = {
  install: function install(Vue) {
    Vue.component(_elementUi.Dialog.name, DialogPathed);
    Vue.component(_elementUi.Drawer.name, DrawerPathed);
  }
};
exports.default = _default;